import React from 'react';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import intlUrl from '@/components/i18n/intlUrl';
import { string, object } from 'prop-types';
import './WidgetDescription.scss';

/**
 * @function WidgetDescription
 * @param {Object} props
 * @param {String} props.text
 * @param {String} [props.href] Pass if you want it to be a link
 * @param {Object} [props.analyticsData]
 * @param {String} [props.className]
 * @param {*} rest use for things like data-hook or additional props that should be passed down
 * @return {React.ReactElement}
 */
const WidgetDescription = ({ text, href, analyticsData, className, ...rest }) => {
	if (!text) return null;

	const { edition } = usePageContext();
	const parsedText = parseHtml(text);

	return href ? (
		<p>
			<a
				className={`widget__description ${className}`}
				href={intlUrl(edition, href)}
				{...analyticsData}
				{...rest}
			>
				{parsedText}
			</a>
		</p>
	) : (
		<p className={`widget__description ${className}`} {...rest}>
			{parsedText}
		</p>
	);
};

WidgetDescription.defaultProps = {
	analyticsData: {},
	className: ''
};

WidgetDescription.propTypes = {
	text: string.isRequired,
	href: string,
	uri: string,
	className: string,
	analyticsData: object
};

WidgetDescription.displayName = 'WidgetDescription';

export default WidgetDescription;
