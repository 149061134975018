import React from 'react';
import SvgImage from '@/components/icons/SvgImage';
import videoIcon from '@/images/video-play-circle.svg';
import galleryIcon from '@/images/camera-icon-circle.svg';
import { string } from 'prop-types';
import './ContentItemIcon.scss';

const iconMap = {
	video: videoIcon,
	gallery: galleryIcon
};

/**
 * @function ContentItemIcon
 * @param {Object} props
 * @param {String} [props.className]
 * @param {String} [props.type]
 * @returns {React.ReactElement}
 */
const ContentItemIcon = ({ className, type }) => {
	return type === 'VIDEO' || type === 'GALLERY' ? (
		<span className={`${className} content-item__icon`}>
			<SvgImage className="content-item__svg" image={iconMap[type.toLowerCase()]} />
		</span>
	) : null;
};

ContentItemIcon.defaultProps = {
	className: ''
};

ContentItemIcon.propTypes = {
	className: string,
	type: string
};

ContentItemIcon.displayName = 'ContentItemIcon';

export default ContentItemIcon;
