import { useQuery } from '@apollo/client';
import LANDING_PAGE from '@/graphql/queries/LANDING_PAGE';
import { usePageContext } from '@/components/layout/page/PageContext';

/**
 * @function useLandingPageQuery
 * @param {String} siteSection
 * @returns {Object}
 */
export default (siteSection) => {
	const { uppercaseEdition } = usePageContext();
	const variables = {
		id: `${uppercaseEdition}-LANDING_PAGE-${siteSection}`,
		edition: uppercaseEdition
	};
	const response = useQuery(LANDING_PAGE, { variables });

	if (response?.error) console.error(response.error.message);

	return response;
};
