import React from 'react';
import MpsAd from '@/components/mps/MpsAd';
import './DockingRightRailAd.scss';

/**
 * @function DockingRightRailAd
 * @description provides an ad element configured with docking behavior for the right rail
 * @returns {React.ReactElement}
 */
const DockingRightRailAd = () => (
	<div className="docking-rr-ad">
		<MpsAd slot="boxflex" className="mps-box-flex" isDesktopOnly={true} centered repeatable />
	</div>
);

DockingRightRailAd.displayName = 'DockingRightRailAd';

export default DockingRightRailAd;
