/**
 * @function getPrevLink
 * @description provides a url string for the previous page in pagination
 * @param {Number} currentPage
 * @param {String} urlPrefix
 * @returns {String}
 */
export const getPrevLink = (currentPage, urlPrefix) => {
	if (currentPage - 1 === 0) return null;
	if (currentPage - 1 === 1) return urlPrefix;

	return `${urlPrefix}/page/${currentPage - 1}`;
};

/**
 * @function getNextLink
 * @description provides a url string for the next page in pagination
 * @param {Number} currentPage
 * @param {Number} lastPage
 * @param {String} urlPrefix
 * @returns {String}
 */
export const getNextLink = (currentPage, lastPage, urlPrefix) => {
	if (currentPage + 1 > lastPage) return null;

	return `${urlPrefix}/page/${currentPage + 1}`;
};
