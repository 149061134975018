import React from 'react';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { PageConsumer } from '@/components/layout/page/PageContext';

/**
 * @function baseDataLayer
 * @description data shared with all pages on site
 * @param {String} edition
 * @returns {Object}
 */
const baseDataLayer = (edition) => {
	return {
		attributes: {
			contentEdition: edition
		}
	};
};

/**
 * @function createDataLayer
 * @description combines baseData layer with page specific data
 * @description to keep the script tag a bit cleaner
 * @param {String} edition
 * @param {Object} pageData - analytics data specific to a page
 * @returns {String} - json string of dataLayer object, Helmet <script> tag accpets strings only
 */
const createDataLayer = (edition, pageData) => {
	return JSON.stringify({
		...baseDataLayer(edition),
		...pageData
	});
};

/**
 * @function DataLayer
 * @description adds dataLayer object for analytics
 * @param {Object} pageData - analytics data specific to a page
 * @returns {React.ReactElement}
 */
const DataLayer = ({ pageData }) => {
	return (
		<PageConsumer>
			{({ edition }) => (
				<Helmet>
					<script>{`window.dataLayer = ${createDataLayer(edition, pageData)}`}</script>
				</Helmet>
			)}
		</PageConsumer>
	);
};

DataLayer.propTypes = {
	pageData: object
};

DataLayer.displayName = 'DataLayer';

export default DataLayer;
