import { gql } from '@apollo/client';

export default gql`
	query CONTENT_BY_CATEGORY(
		$categoryKey: String!
		$edition: EDITIONKEY!
		$skip: Int
		$limit: Limit
	) {
		articles(
			filter: { categoryKey: $categoryKey, edition: $edition, skip: $skip, limit: $limit }
		) {
			nodes {
				type
				snipe
				shortTitle
				id
				title
				edition
				publishDate
				uri(edition: $edition)
				thumbnail(edition: $edition) {
					uri
					id
				}
			}
		}
	}
`;
