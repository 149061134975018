import React, { useState, Fragment } from 'react';
import { any, object, string } from 'prop-types';
import SvgImage from '@/components/icons/SvgImage';
import eNewsLogo from '@/images/elogo/ENewsLogo-fill.svg';
import parseHtml from '@/utils/parseHtml';
import MpsAd from '@/components/mps/MpsAd';
import Translate from '@/components/i18n/Translate';
import './SponsoredBy.scss';

/**
 * @function SponsoredText
 * @param {String} props.children - "SPONSORED" or "BRANDED"
 * @returns {React.ReactElement}
 */
const SponsoredText = ({ children }) => (
	<span className="sponsored__text" data-hook="sponsored-text">
		{children}
	</span>
);

SponsoredText.propTypes = {
	children: any.isRequired
};

SponsoredText.displayName = 'SponsoredText';

/**
 * @function DefaultText
 * @description displays the correct default text depending on brandingType
 * @param {String} brandingType
 * @returns {React.ReactElement}
 */
const DefaultText = ({ brandingType }) => {
	return brandingType === 'SPONSORED' ? (
		<SponsoredText>Presented By</SponsoredText>
	) : brandingType === 'BRANDED' ? (
		<SponsoredText>
			<Translate word={'By'} /> <SvgImage className="sponsored__e-logo" image={eNewsLogo} /> &
		</SponsoredText>
	) : null;
};

DefaultText.propTypes = {
	brandingType: string.isRequired
};

DefaultText.displayName = 'DefaultText';

/**
 * @function SponsoredBy
 * @description provides sponsor/branded byline or attribution
 * @param {Object} [props.ads]
 * @param {String|null} [props.brandingType]
 * @param {String} [props.className]
 * @param {String|null} [props.displayText] - override text
 * @param {String|null} [props.advertiserLogoOverride]
 * @param {String|null} [props.disclosureText]
 * @return {React.ReactElement}
 */
const SponsoredBy = ({
	ads,
	brandingType,
	className,
	displayText,
	advertiserLogoOverride,
	disclosureText
}) => {
	const [isAdSuccess, setIsAdSuccess] = useState(false);
	const [isAdError, setIsAdError] = useState(false);

	// to keep from having flash of content while ad is being called
	const [isAdCallFinished, setIsAdCallFinished] = useState(false);

	const keyword = ads?.adKeywords;
	if (!keyword && !disclosureText) return null;
	return (
		<div className={`sponsored ${className}`} data-hook="sponsoredby">
			{disclosureText ? (
				<SponsoredText>{parseHtml(disclosureText)}</SponsoredText>
			) : // if there is an advertiserLogoOverride we display the default text + advertiserLogoOverride
			advertiserLogoOverride ? (
				<Fragment>
					<DefaultText brandingType={brandingType} />
					<span className="sponsored__text" data-hook="sponsored-text-logo-override">
						{advertiserLogoOverride}
					</span>
				</Fragment>
			) : (
				<Fragment>
					{/**
						if there is NOT an advertiserLogoOverride
							we should
								display the default text by brandingType + Ad Logo if the MPS ad call is NOT successful
							or
								display the displayText if the MPS ad call is successful

						this displayText shoud show ONLY when an adKeywords is:
							expired
							not running for a specific edition,
							incorrect / misspelled
					*/}
					{displayText && isAdCallFinished && isAdError && (
						<SponsoredText>{parseHtml(displayText)}</SponsoredText>
					)}
					{isAdCallFinished && isAdSuccess && <DefaultText brandingType={brandingType} />}
					<MpsAd
						slot="logorepeat"
						keywords={keyword}
						className="sponsored__logo"
						success={(eo) => {
							setIsAdCallFinished(true);
							setIsAdSuccess(true);
						}}
						error={(eo) => {
							setIsAdCallFinished(true);
							setIsAdError(true);
						}}
						repeatable
					/>
				</Fragment>
			)}
		</div>
	);
};

SponsoredBy.defaultProps = {
	className: ''
};

SponsoredBy.propTypes = {
	ads: object,
	brandingType: string,
	className: string,
	displayText: string,
	advertiserLogoOverride: string,
	disclosureText: string
};

SponsoredBy.displayName = 'SponsoredBy';

export default SponsoredBy;
