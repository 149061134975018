import React, { useContext } from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import List from '@/components/list/List';
import TRENDING from '@/graphql/queries/TRENDING';
import { useQuery } from '@apollo/client';
import { PageContext } from '@/components/layout/page/PageContext';
import './WidgetTrending.scss';

/**
 * @function WidgetTrending
 * @descrtion displays top stories
 * @param {Number | String} [props.count] - total stories to be displayed
 * @param {Boolean} [props.hideAuthors]
 * @param {Boolean} [props.hideDate]
 * @param {Boolean} [props.hideSeeMore]
 * @param {String} props.location
 * @param {Boolean} [props.showCount]
 * @param {Boolean} [props.showThumbs]
 * @param {Boolean} [props.showThumbCount]
 * @param {String} [props.title]
 * @return {React.ReactElement}
 */
const WidgetTrending = ({
	count,
	hideAuthors,
	hideDate,
	hideSeeMore,
	location,
	showCount,
	showThumbs,
	showThumbCount,
	title
}) => {
	const { edition } = useContext(PageContext);
	const { data, error } = useQuery(TRENDING, {
		variables: { edition: edition.toUpperCase(), limit: count }
	});

	if (!data?.trendingContent) {
		if (error) console.log('error in Trending Content query:', error.message);
		return null;
	}
	const header = { title };
	const seeMoreAnalyticsData = {
		'data-analytics-linkname': 'see-more',
		'data-analytics-linklocation': 'trending-right-rail'
	};

	return (
		<List
			className="trending-content"
			header={header}
			contentItems={data?.trendingContent}
			showMeta={false}
			analyticsData={() => ({
				'data-widget-type': 'trending-stories-widget',
				'data-widget-template': 'TRENDING',
				'data-widget-position': 'sec0-wid0',
				'data-widget-data-type': 'trending',
				'data-widget-category': 'trending-content'
			})}
			options={{
				hideAuthors,
				hideDate,
				hideSeeMore,
				showCount,
				showThumbs,
				showThumbCount
			}}
			seeMoreAnalyticsData={seeMoreAnalyticsData}
		/>
	);
};

WidgetTrending.defaultProps = {
	count: 5,
	hideAuthors: true,
	hideDate: true,
	hideSeeMore: true,
	showCount: false,
	showThumbs: true,
	showThumbCount: true,
	title: 'TRENDING_STORIES'
};

WidgetTrending.propTypes = {
	count: oneOfType([number, string]),
	hideAuthors: bool,
	hideDate: bool,
	hideSeeMore: bool,
	location: string.isRequired,
	showCount: bool,
	showThumbs: bool,
	showThumbCount: bool,
	title: string
};

WidgetTrending.displayName = 'WidgetTrending';

export default WidgetTrending;
