import React, { memo } from 'react';
import TimeElapsed from '@/components/date/TimeElapsed';
import { string } from 'prop-types';
import './WidgetDate.scss';

/**
 * @function Date
 * @param {Object} props
 * @param {String} [props.className]
 * @param {String|null} [props.date]
 * @return {React.ReactElement}
 */
const WidgetDate = ({ className, date }) => {
	return date ? (
		<p className={`widget__date ${className || ''}`} data-hook="date">
			<TimeElapsed date={date} />
		</p>
	) : null;
};

WidgetDate.propTypes = {
	className: string,
	date: string
};

WidgetDate.displayName = 'WidgetDate';

export default memo(WidgetDate);
