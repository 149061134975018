import React, { Fragment, useEffect, useState } from 'react';
import TopTwo from './TopTwo';
import { array, number } from 'prop-types';
import Pagination from '@/components/pagination/Pagination';
import { usePageContext } from '@/components/layout/page/PageContext';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import WidgetTrending from '@/components/widgets/WidgetTrending';
import Taboola from '@/components/taboola/Taboola';
import DockingRightRailAd from '@/components/ads/DockingRightRailAd';
import LatestNews from '@/components/pages/article-detail/LatestNews';
import MpsAd from '@/components/mps/MpsAd';
import ContentItem from './ContentItem';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import StructuredData from '@/components/structured-data/StructuredData';
import './ContentFeed.scss';

/**
 * @const showAdThreshold
 * @description show Right Rail ad if at least this many items are passed in
 */
const showAdThreshold = 5;

/**
 * @const interstitialAdFrequency
 * @description interval of content items after which to show interstitial ad
 */
const interstitialAdFrequency = 6;

/**
 * @function getItemListElements
 * @description generates an array of "ListItem" objects
 * @param {Array} contentItems
 * @returns {Array}
 */
const getItemListElements = (contentItems) =>
	contentItems.map((contentItem, index) => {
		return {
			'@type': 'ListItem',
			position: index,
			url: `https://www.eonline.com${contentItem.uri}`
		};
	});

/**
 * @function ContentFeed
 * @description provides a Content Feed component
 * @param {Array} props.contentItems
 * @param {Number} props.totalCount
 * @returns {React.ReactElement}
 */
const ContentFeed = ({ contentItems, totalCount }) => {
	const [breakpointWidth, setBreakpointWidth] = useState(null);

	const { edition } = usePageContext();
	const { META_CONTENT_ENABLED, TABOOLA_TRENDING_STORIES_PARAM } = useParamToggleContext();
	const { analyticsPageType, currentPage, limit, location, urlPrefix } = useLandingPageContext();
	const isAuthorDetail = location === 'author-detail';
	const items = isAuthorDetail
		? contentItems
		: currentPage === 1
		? contentItems.slice(2)
		: contentItems;
	const showRightRailAd = items.length >= showAdThreshold;
	const includePagination = totalCount > limit;

	/*
	 * Page 1 puts the first two content items into a separate
	 * component (TopTwo), so this logic handles page 1 differently from other pages.
	 */
	const firstPage = currentPage === 1;
	const firstAdIndex = firstPage ? 0 : 2;

	useEffect(() => {
		setBreakpointWidth(window.innerWidth >= '1024' ? 'desktop' : 'mobile');
	}, []);

	return (
		<section
			className={`content-feed ${META_CONTENT_ENABLED ? 'content-feed--meta-content' : ''}`}
			data-hook="content-feed"
		>
			{!isAuthorDetail && currentPage === 1 && <TopTwo items={contentItems.slice(0, 2)} />}

			{items.length > 0 && (
				<Fragment>
					<div
						className="content-items__wrapper columns"
						data-hook="content-items-wrapper"
					>
						<div className="content-items column" data-hook="content-items">
							{items.map((item, index) => {
								const includeAd =
									index === firstAdIndex ||
									(index + firstAdIndex) % interstitialAdFrequency === 0;

								return (
									<Fragment key={`content-item-${item.id}`}>
										{includeAd && (
											<div
												className="content-items__interstitial-ad"
												data-hook="interstitial-ad"
											>
												<MpsAd
													slot="midbanner"
													className="mps-interstitial"
													centered
													isHiddenDesktop={true}
													repeatable
												/>
											</div>
										)}
										<ContentItem
											item={item}
											className={index === 0 ? 'content-item--first' : ''}
										/>
									</Fragment>
								);
							})}
							{includePagination && (
								<Pagination
									analyticsLinkLocation={analyticsPageType}
									currentPage={currentPage}
									limit={limit}
									totalCount={totalCount}
									urlPrefix={urlPrefix}
								/>
							)}
						</div>

						{/* Right Rail - only visible in Desktop viewport */}
						<div
							className="content-items__rightrail column is-4"
							data-testid="content-feed-right-rail"
						>
							{showRightRailAd && <DockingRightRailAd />}
							{TABOOLA_TRENDING_STORIES_PARAM ? (
								breakpointWidth === 'desktop' && (
									<Taboola
										pageName="category"
										initData={[
											{
												placementName:
													'Desktop Right Rail Landing Page - Content Recs',
												mode: 'organic-thumbnails-trending',
												instancesToGenerate: 1
											}
										]}
										generateContainers={true}
										isOnLoad={true}
									/>
								)
							) : (
								<WidgetTrending
									location={location}
									hideDate={false}
									hideSeeMore={false}
									showCount={true}
									showThumbs={false}
								/>
							)}
							{/ca/i.test(edition) ? (
								<LatestNews />
							) : (
								<div id="right-rail-landing-page-thumbnails"></div>
							)}
						</div>
					</div>
				</Fragment>
			)}
			<StructuredData
				data={{
					'@type': 'ItemList',
					itemListElement: getItemListElements(contentItems)
				}}
			/>
		</section>
	);
};

ContentFeed.propTypes = {
	contentItems: array.isRequired,
	totalCount: number.isRequired
};

ContentFeed.displayName = 'ContentFeed';

export default ContentFeed;
