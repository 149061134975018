import React, { Fragment } from 'react';
import { object, string } from 'prop-types';
import Thumb from '@/components/thumb/Thumb';
import parseHtml from '@/utils/parseHtml';
import './TitleHeader.scss';

/**
 * @function TitleHeader
 * @param {Object} props
 * @param {Object} [props.children]
 * @param {String} [props.className]
 * @param {Object} [props.thumbnail]
 * @param {String} [props.title]
 * @param {String} [props.subTitle]
 * @param {String} [props.styleType] DEFAULT or SECONDARY (centered style)
 * @returns {React.ReactElement}
 */
const TitleHeader = ({ children, className, thumbnail, title, subTitle, styleType }) => {
	const modifier = styleType === 'SECONDARY' ? 'title-header--secondary' : '';
	return thumbnail || title ? (
		<Fragment>
			{thumbnail && (
				<div className="title-header__thumb">
					<Thumb type="2x1" thumbnail={thumbnail} width="500" withLink={false} />
				</div>
			)}
			{title && (
				<div className={`title-header ${modifier} ${className}`}>
					{styleType === 'SECONDARY' && <div className="title-header__line" />}
					<h1>{parseHtml(title)}</h1>
					{children}
					<div className="title-header__line" />
				</div>
			)}
		</Fragment>
	) : null;
};

TitleHeader.defaultProps = {
	className: '',
	styleType: 'DEFAULT'
};

TitleHeader.propTypes = {
	children: object,
	className: string,
	thumbnail: object,
	title: string,
	subTitle: string,
	styleType: string
};

TitleHeader.displayName = 'TitleHeader';

export default TitleHeader;
