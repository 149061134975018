/**
 * @function removeDuplicates
 * @description removes duplicates from an array and returns array of unique values
 * @example const filteredArray = myArray.filter(removeDuplicates);
 * @param {*} value
 * @param {Number} index
 * @param {Array} arr
 * @returns {Array}
 */
export default (value, index, arr) => arr.indexOf(value) === index;
