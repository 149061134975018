import { gql } from '@apollo/client';
import FragmentGalleryData from './FRAGMENT_GALLERY_DATA';
/**
 * @const FragmentGalleryData
 * @description GraphQL fragment on Gallery Folder implementation of LinkList interface.
 */

const FragmentGalleryFolderData = gql`
	${FragmentGalleryData}
	fragment GalleryFolderData on GalleryFolder {
		folderKey
		title
		updatedAt
		createdAt
		gfThumbnail: thumbnail(edition: $edition) {
			id
			title
			uri
			sourceWidth
			sourceHeight
			agency
		}
		galleries {
			...GalleryData
		}
	}
`;

export default FragmentGalleryFolderData;