/* Keep a cache of the pageName's that already exist. This is to avoid pushing to taboola the pageName when it was already defined. This can happen with unexpected rerenders or if there are 2 instances of Taboola on a pg. Ex. Video Detail page.
IMPORTANT: DO NOT EXPORT THIS OBJECT!!! This object needs to be new per page load */
const exists = {};

/**
 * @string taboolaEnd
 * @description meant to be used after initializing taboola
 */
const taboolaEnd = () => {
	window._taboola = window._taboola || [];
	_taboola.push({ flush: true });
};

/**
 * @function taboolaHead
 * @description is meant to be used inside the <head> tag.
 * @param {String} pageName
 */
export const loadTaboola = (pageName) => {
	window._taboola = window._taboola || [];
	if (!exists[pageName]) {
		// Set the pageName to exist
		exists[pageName] = true;
		_taboola.push({ [pageName]: 'auto' });

		const loaderScriptId = 'tb_loader_script';
		const loaderScriptSrc = 'https://cdn.taboola.com/libtrc/eonline-network/loader.js';

		if (!document.getElementById(loaderScriptId)) {
			if (window.OneTrust) {
				// if OneTrust has loaded on the page, insert the script inline
				window.OneTrust.InsertScript(loaderScriptSrc, 'head', taboolaEnd, null, '4');
				// since can't add an ID attribute to the above, add it here so we know script has been inserted
				const optanonHtml = `<div id="${loaderScriptId}"></div>`;
				window.OneTrust.InsertHtml(optanonHtml, 'root', null, null, '4');
			} else {
				// otherewise add as a script element as normal
				(function (e, f) {
					e.type = 'text/plain';
					e.setAttribute('class', 'optanon-category-4');
					e.src = loaderScriptSrc;
					e.id = loaderScriptId;
					f.parentNode.insertBefore(e, f);
					taboolaEnd();
				})(document.createElement('script'), document.getElementsByTagName('script')[0]);
			}
		}

		if (window.performance && typeof window.performance.mark === 'function') {
			window.performance.mark('tbl_ic');
		}
	}
};

/**
 * @function newsroomHome
 * @description is meant to be used inside the <head> tag on the homepage
 * @returns {Boolean}
 */
export const newsroomHome = () => {
	if (typeof window === 'undefined') return false;

	window._newsroom = window._newsroom || [];
	window._newsroom.push({ pageTemplate: 'home' });
	window._newsroom.push({ pageDashboard: 'home' });
	window._newsroom.push('auditClicks');
	window._newsroom.push('trackPage');

	return !(function (e, f, u) {
		e.type = 'text/plain';
		e.setAttribute('class', 'optanon-category-4');
		e.src = u;
		f.parentNode.insertBefore(e, f);
	})(
		document.createElement('script'),
		document.getElementsByTagName('script')[0],
		'https://c2.taboola.com/nr/eonline-eonline/newsroom.js'
	);
};

/**
 * @function newsroom
 * @description is meant to be used inside the <head> tag in select pages other than the homepage
 * @returns {Boolean}
 */
export const newsroom = () => {
	if (typeof window === 'undefined') return false;

	window._newsroom = window._newsroom || [];

	return !(function (e, f, u) {
		e.type = 'text/plain';
		e.setAttribute('class', 'optanon-category-4');
		e.src = u;
		f.parentNode.insertBefore(e, f);
	})(
		document.createElement('script'),
		document.getElementsByTagName('script')[0],
		'https://c2.taboola.com/nr/eonline-eonline/newsroom.js'
	);
};
