import { gql } from '@apollo/client';

export default gql`
	query CONTENT_LINKABLES(
		$contentTypes: [CONTENT_TYPE]
		$categoryKey: String
		$edition: EDITIONKEY!
		$excludeBranded: Boolean
		$skip: Int
		$limit: Limit
	) {
		contentLinkables(
			filter: {
				contentTypes: $contentTypes
				categoryKey: $categoryKey
				edition: $edition
				excludeBranded: $excludeBranded
				skip: $skip
				limit: $limit
				workflowLabel: [WEB_CONTENT_FEEDS]
			}
		) {
			nodes {
				id
				title
				shortTitle
				uri(edition: $edition)
				snipe
				firstPublishDate
				thumbnail(edition: $edition) {
					uri
					id
				}
				publishDate
				type
				... on Article {
					subhead
				}
				... on Gallery {
					subhead
				}
				... on Video {
					description
				}
			}
			totalCount
		}
	}
`;
