import React from 'react';
import StructuredData from '@/components/structured-data/StructuredData';
import { usePageContext } from '@/components/layout/page/PageContext';
import { APP_HOSTNAME } from '@/config/config';

/**
 * @function HomeStructuredData
 * @description compiles the structured data for home
 * @returns {React.ReactElement}
 */
const HomeStructuredData = () => {
	const { edition } = usePageContext();

	return (
		<StructuredData
			data={{
				'@type': 'Organization',
				name: 'E! Online',
				url: `https://${APP_HOSTNAME}${edition === 'us' ? '' : '/' + edition}`,
				logo:
					'https://akns-images.eonline.com/eol_images/Entire_Site/2020330/rs_112x112-200430123021-112X112-e-logo.png',
				...(edition === 'us'
					? {
							sameAs: [
								'https://www.facebook.com/enews/',
								'https://twitter.com/enews',
								'https://www.pinterest.com/e_news_/',
								'https://www.instagram.com/enews',
								'https://www.youtube.com/user/enews'
							]
					  }
					: {})
			}}
		/>
	);
};

HomeStructuredData.displayName = 'HomeStructuredData';

export default HomeStructuredData;
