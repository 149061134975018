import React from 'react';
import { array } from 'prop-types';

/**
 * @function FourEight
 * @param {Object} props
 * @param {Array} props.children
 * @return {React.ReactElement}
 */
const FourEight = ({ children }) => {
	return (
		<div className="columns">
			<div className="column is-one-third is-full-mobile">{children[0]}</div>
			<div className="column is-8 is-full-mobile">{children[1]}</div>
		</div>
	);
};

FourEight.propTypes = {
	children: array.isRequired
};

FourEight.displayName = 'FourEight';

export default FourEight;
