import React, { Fragment } from 'react';
import Thumb from '@/components/thumb/Thumb';
import { string, object, bool } from 'prop-types';
import Translate from '@/components/i18n/Translate';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import parseHtml from '@/utils/parseHtml';
import NoWrap from '@/components/nowrap/NoWrap';
import './WidgetHeader.scss';

/**
 * @function A
 * @param {Object} props
 * @return {React.ReactElement}
 */
// eslint-disable-next-line jsx-a11y/anchor-has-content
const A = (props) => <a {...props} />;

A.displayName = 'A';

/**
 * @function H1
 * @param {Object} props
 * @return {React.ReactElement}
 */
// eslint-disable-next-line jsx-a11y/heading-has-content
const H1 = (props) => <h1 {...props} />;

H1.displayName = 'H1';

/**
 * @function H4
 * @param {Object} props
 * @return {React.ReactElement}
 */
// eslint-disable-next-line jsx-a11y/heading-has-content
const H4 = (props) => <h4 {...props} />;

H4.displayName = 'H4';

/**
 * @function WidgetHeader
 * @param {Object} props
 * @param {String|null} [props.title]
 * @param {Object} [props.thumbnail]
 * @param {String|null} [props.className]
 * @param {String|null} [props.widgetAdKeyword]
 * @param {String|null} [props.brandedTextOverride]
 * @param {String|null} [props.advertiserLogoOverride]
 * @param {String|null} [props.brandingType]
 * @param {String|null} [props.uri] a header link
 * @param {Boolean} [props.hasH1Tag] whether a h1 tag is desired or not
 * @param {Boolean} [props.isWithLine] whether the header should have a line
 * @param {Object} [props.analyticsData] analytics data for when it contains a header link
 * @return {React.ReactElement}
 */
const WidgetHeader = ({
	title,
	thumbnail,
	className,
	widgetAdKeyword,
	brandedTextOverride,
	advertiserLogoOverride,
	brandingType,
	isWithLine,
	analyticsData,
	uri,
	hasH1Tag
}) => {
	/* if brandingType came back null it means "NONE" was selected in the CMS */
	const isAdEnabled = widgetAdKeyword && brandingType !== null;
	const Wrap = uri ? A : NoWrap;
	const HeaderWrap = hasH1Tag ? H1 : H4;
	return title || thumbnail || widgetAdKeyword ? (
		<Fragment>
			{thumbnail && (
				<div className="widget__header-thumb">
					<Thumb type="2x1" thumbnail={thumbnail} width="500" withLink={false} />
				</div>
			)}
			{(title || isAdEnabled) && (
				<div
					className={`widget__header 
						${isAdEnabled ? 'widget__header--sponsored' : ''} 
						${isAdEnabled && !title ? 'widget__header--sponsored-no-title' : ''} 
						${isWithLine ? 'widget__header--with-line' : ''}
						${className}`}
					data-hook="widget__header"
				>
					{title && (
						<HeaderWrap>
							<Wrap href={uri} data-tb-region-item {...analyticsData}>
								<span>
									<Translate word={parseHtml(title)} />
								</span>
							</Wrap>
						</HeaderWrap>
					)}
					{isAdEnabled && (
						<div className="widget__header-sponsor">
							<SponsoredBy
								brandingType={brandingType}
								ads={{ adKeywords: widgetAdKeyword }}
								displayText={brandedTextOverride}
								advertiserLogoOverride={advertiserLogoOverride}
								className="is-header"
							/>
						</div>
					)}
				</div>
			)}
		</Fragment>
	) : null;
};

WidgetHeader.defaultProps = {
	className: '',
	isWithLine: true,
	analyticsData: {},
	hasH1Tag: false
};

WidgetHeader.propTypes = {
	title: string,
	uri: string,
	thumbnail: object,
	className: string,
	widgetAdKeyword: string,
	brandedTextOverride: string,
	advertiserLogoOverride: string,
	brandingType: string,
	isWithLine: bool,
	analyticsData: object,
	hasH1Tag: bool
};

WidgetHeader.displayName = 'WidgetHeader';

export default WidgetHeader;
