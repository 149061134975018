import { gql } from '@apollo/client';

export default gql`
	query THEME($id: ID!) {
		theme(id: $id) {
			hotTopicsColor
			hoverColor
			navHoverColor
			logoColor
			notificationBarColor
			pageBackgroundColor
			pageBackgroundImage {
				id
				uri
			}
			pageIds
			primaryColor
			secondaryColor
			pageTitleColor
			widgetTheme
			activeDate
			expireDate
		}
	}
`;
