import React from 'react';
import { array, string, bool } from 'prop-types';
import parseHtml from '@/utils/parseHtml';
import WidgetDate from '@/components/widgets/WidgetDate';
import WidgetDescription from '@/components/widgets/WidgetDescription';
import WidgetAuthor from '@/components/widgets/WidgetAuthor';
import './Caption.scss';

/**
 * @function Caption
 * @description adds stylized caption
 * @param {Object} props
 * @param {Array} [props.authors]
 * @param {String} [props.publishDate]
 * @param {String} props.title
 * @param {String} [props.description]
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.descriptionEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @returns {React.ReactElement}
 */
const Caption = ({
	authors,
	publishDate,
	title,
	description,
	timestampsEnabled,
	descriptionEnabled,
	authorBylineEnabled
}) => {
	return (
		<div className="caption">
			<span className="caption__container">
				<h4 className="caption__text is-9">{parseHtml(title)}</h4>
			</span>
			{authorBylineEnabled && <WidgetAuthor authors={authors} />}
			{descriptionEnabled && description && <WidgetDescription text={description} />}
			{timestampsEnabled && <WidgetDate date={publishDate} />}
		</div>
	);
};

Caption.propTypes = {
	authors: array,
	publishDate: string,
	title: string.isRequired,
	description: string,
	timestampsEnabled: bool,
	descriptionEnabled: bool,
	authorBylineEnabled: bool
};

Caption.displayName = 'Caption';

export default Caption;
