import React from 'react';
import { array, object, string } from 'prop-types';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import Hero from '@/components/widgets/hero/Hero';
import './TopTwo.scss';

/**
 * @function TopTwoItem
 * @description renders a single content item for TopTwo
 * @param {Object} props
 * @param {String} props.id
 * @param {String} [props.publishDate]
 * @param {Object} props.thumbnail
 * @param {String} props.title
 * @param {String} [props.type]
 * @param {String} props.id
 * @param {String} [props.subhead]
 * @param {String} [props.description]
 * @returns {React.ReactElement}
 */
const TopTwoItem = (props) => {
	const { analyticsCategory, analyticsPageType } = useLandingPageContext();
	const topTwoAnalyticsData = {
		'data-analytics-feedcontenttype': props?.type?.toLowerCase(),
		'data-analytics-linkname': `${analyticsCategory}-content-feed-top2`,
		'data-analytics-linklocation': analyticsPageType
	};

	return (
		<div
			key={`top-two-${props.id}`}
			className="top-two__item column is-6"
			data-hook="top-two-item"
		>
			<Hero
				analyticsData={topTwoAnalyticsData}
				width="660"
				thumbType="16x9"
				description={props.subhead || props.description || null}
				descriptionEnabled={true}
				timestampsEnabled={true}
				{...props}
			/>
		</div>
	);
};

TopTwoItem.propTypes = {
	id: string.isRequired,
	publishDate: string,
	thumbnail: object.isRequired,
	title: string.isRequired,
	type: string,
	uri: string.isRequired,
	subhead: string,
	description: string
};

TopTwoItem.displayName = 'TopTwoItem';

/**
 * @function TopTwo
 * @description provides hero with two content items
 * @param {Array} items
 * @returns {React.ReactElement}
 */
const TopTwo = ({ items }) =>
	items && items.length > 0 ? (
		<div className="top-two columns is-tablet" data-hook="top-two">
			<TopTwoItem {...items[0]} />
			{items[1] && <TopTwoItem {...items[1]} />}
		</div>
	) : null;

TopTwo.propTypes = {
	items: array
};

TopTwo.displayName = 'TopTwo';

export default TopTwo;
