import React from 'react';
import { string, object } from 'prop-types';
import chevron from '@/images/chevron.svg';
import SvgImage from '@/components/icons/SvgImage';
import Translate from '@/components/i18n/Translate';
import './SeeMore.scss';

/**
 * @function SeeMore
 * @param {Object} props
 * @param {Object} [props.analyticsData]
 * @param {String} [props.link]
 * @returns {React.ReactElement}
 */
const SeeMore = ({ analyticsData, link }) =>
	link ? (
		<div data-hook="see-more" data-tb-region-item>
			<a className="see-more" href={link} {...analyticsData}>
				<Translate word="SEE_MORE" />
				<SvgImage image={chevron} className="see-more__chevron" />
			</a>
		</div>
	) : null;

SeeMore.propTypes = {
	analyticsData: object,
	link: string
};

SeeMore.displayName = 'SeeMore';

export default SeeMore;
