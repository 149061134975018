import { gql } from '@apollo/client';

/**
 * @const FragmentArticleData
 * @description GraphQL fragment on Article implementation of LinkList interface.
 */
const FragmentArticleData = gql`
	fragment ArticleData on Article {
		uri(edition: $edition)
		authors {
			id
			fullName
			uri
		}
		branding {
			brandingType
			displayText
			ads {
				adKeywords
			}
			adTracking {
				advertiser
			}
		}
		publishDate
		firstPublishDate
		shortTitle
		snipe
	}
`;

export default FragmentArticleData;
