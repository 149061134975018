import React from 'react';
import { Helmet } from 'react-helmet-async';
import intlUrl from '../intlUrl';
import * as langs from './hreflangs';
import * as editions from '../editions';
import { APP_HOSTNAME } from '@/config/config';
import { string, array } from 'prop-types';

// The app hostname
const CLIENT_HOSTNAME = `https://${APP_HOSTNAME}`;

// An array of editions
const EDITIONS_ARRAY = Object.keys(editions);

/**
 * @function hardcodedHrefLang
 * @description Build array of editions for hreflangs
 * @param {Array} editions
 * @param {String} uri
 * @return {Array}
 */
const hardcodedHrefLang = (editions, uri) => {
	return editions.map((edition) => ({
		edition,
		uri: `${CLIENT_HOSTNAME}${intlUrl(edition, uri)}`
	}));
};

/**
 * @function hrefLang
 * @param {Object} props
 * @param {String} props.uri relative uri (if hardcoded) or absolute link used to populate x-default
 * @param {Array} [props.hrefLang] dynamic hrefLang coming from gql
 * @return {React.ReactElement}
 */
const HrefLang = ({ uri, hrefLang }) => {
	if (typeof uri !== 'string') return null;
	const isDynamic = Array.isArray(hrefLang);
	const xDefaultUrl = isDynamic ? uri : `${CLIENT_HOSTNAME}${intlUrl('US', uri)}`;
	const editions = EDITIONS_ARRAY;
	const hrefLangArr = isDynamic ? hrefLang : hardcodedHrefLang(editions, uri);
	return (
		<Helmet>
			<link key="x-default" rel="alternate" href={xDefaultUrl} hrefLang="x-default" />
			{hrefLangArr.map(({ edition, uri }) => {
				const hrefLinks = langs[edition] || [];
				return hrefLinks.map((lang) => {
					return uri ? (
						<link key={lang} rel="alternate" href={uri} hrefLang={lang} />
					) : null;
				});
			})}
		</Helmet>
	);
};

HrefLang.displayName = 'HrefLang';

HrefLang.propTypes = {
	uri: string.isRequired,
	hrefLang: array
};

export default HrefLang;
