import React from 'react';
import { array } from 'prop-types';

/**
 * @function EightFour
 * @param {Object} props
 * @param {array} props.children
 * @return {React.ReactElement}
 */
const EightFour = ({ children }) => {
	return (
		<div className="columns">
			<div className="column is-8 is-full-mobile">{children[0]}</div>
			<div className="column is-one-third is-full-mobile">{children[1]}</div>
		</div>
	);
};

EightFour.propTypes = {
	children: array.isRequired
};

EightFour.displayName = 'EightFour';

export default EightFour;
