export const US = 'US';
export const AU = 'AU';
export const UK = 'UK';
export const CA = 'CA';
export const AP = 'AP';
export const DE = 'DE';
export const FR = 'FR';
export const AR = 'AR';
export const BR = 'BR';
export const CO = 'CO';
export const MX = 'MX';
export const VE = 'VE';
