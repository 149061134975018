import React from 'react';
import { array } from 'prop-types';

/**
 * @function Thirds
 * @param {Object} props
 * @param {Array} props.children
 * @return {React.ReactElement}
 */
const Thirds = ({ children }) => {
	return (
		<div className="columns is-tablet">
			<div className="column is-one-third-desktop is-full-tablet is-full-mobile">
				{children[0]}
			</div>
			<div className="column is-one-third-desktop is-half-tablet is-full-mobile">
				{children[1]}
			</div>
			<div className="column is-one-third-desktop is-half-tablet is-full-mobile">
				{children[2]}
			</div>
		</div>
	);
};

Thirds.propTypes = {
	children: array.isRequired
};

Thirds.displayName = 'Thirds';

export default Thirds;
