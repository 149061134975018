import React from 'react';

/**
 * @function withMapToComponent
 * @description A helper HOC that will map to a component based on its ENUM type
 * @param {Object} components - A hashmap of components
 * @example { TYPE_1 : Component, TYPE_2 : Component }
 * @return {Function} Returns a HOC Component
 */
const withMapToComponent = components => props => {
	// Normalize type to uppercase
	const componentType = props.type.toUpperCase();
	const Component = components[componentType];
	// If the Component exists, return and pass along the props
	return Component ? <Component {...props} /> : null;
};

export default withMapToComponent;
