import React from 'react';
import { array, string } from 'prop-types';
import { useFeatureToggleContext } from '@/components/layout/page/PageContext';
import Taboola from '@/components/taboola/Taboola';
import ThemeFullWidthWrap from '@/components/page-theme/ThemeFullWidthWrap';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { useResizePageObserver } from '@/components/layout/page/ResizePageObserver';
import breakpointType from '@/utils/breakpointType';
import {
	baseLandingPageTaboolaConfig,
	rightRailTaboolaConfig,
	taboolaStoriesConfig
} from '@/config/taboolaConfigs';

/**
 * @function LandingPageTaboola
 * @param {Array} [props.taboolaConfig]
 * @param {String|Null} [props.taboolaStoriesType]
 * @returns {React.ReactElement}
 */
const LandingPageTaboola = ({ taboolaConfig, taboolaStoriesType }) => {
	const { siteSection, showContentFeed } = useLandingPageContext();
	const { TABOOLA_THEME_ENABLED, TABOOLA_STORIES_ENABLED } = useFeatureToggleContext();
	const { THEMED_PAGE, TABOOLA_STORIES_ENABLED_PARAM } = useParamToggleContext();

	// Determine viewport type
	const { width } = useResizePageObserver();
	const type = breakpointType(width);
	const taboolaToggleRules = TABOOLA_STORIES_ENABLED || TABOOLA_STORIES_ENABLED_PARAM;

	// Include the Taboola Stories config when:
	// 1. user is on News Landing
	// 2. user is on a mobile viewport
	// 3. toggle rules are met
	const includeTaboolaStories =
		typeof window === 'object' && taboolaToggleRules && taboolaStoriesType && type === 'mobile';

	// Include the right rail Taboola config when:
	// 1. there is a content feed
	// 2. user is on News Landing or Video Landing only
	const includeRightRailTaboolaConfig =
		showContentFeed && (siteSection === 'news' || siteSection === 'videos');

	// Build merged Taboola config
	const mergedTaboolaConfig = [
		...taboolaConfig,
		includeRightRailTaboolaConfig && rightRailTaboolaConfig,
		includeTaboolaStories && taboolaStoriesConfig(taboolaStoriesType)
	].filter(Boolean);

	const hasThemeWrap = !(TABOOLA_THEME_ENABLED || THEMED_PAGE) || siteSection !== 'home';

	return (
		<ThemeFullWidthWrap hasWrap={hasThemeWrap}>
			<Taboola
				pageName={siteSection === 'home' ? 'home' : 'category'}
				initData={mergedTaboolaConfig}
				generateContainers={siteSection === 'home'}
				isOnLoad={!!taboolaStoriesType}
			/>

			{/* Other than on homepage, add a div for taboola to render its 
				feed at the bottom */}
			{siteSection !== 'home' ? <div id="below-landing-page-thumbnails-v2"></div> : null}
		</ThemeFullWidthWrap>
	);
};

LandingPageTaboola.defaultProps = {
	taboolaConfig: baseLandingPageTaboolaConfig
};

LandingPageTaboola.propTypes = {
	taboolaConfig: array,
	taboolaStoriesType: string
};

LandingPageTaboola.displayName = LandingPageTaboola;

export default LandingPageTaboola;
