import React from 'react';
import loadable from '@loadable/component';

const Loading = () => null;
const fallBack = { fallback: <Loading /> };

/**
 * @function AD
 * @description lazy loads Ad Widget
 */
export const AD = loadable(
	() => import(/* webpackChunkName: "AdWidget" */ './ad/AdWidget'),
	fallBack
);

/**
 * @function LIST
 * @description lazy loads List Widget
 */
export const LIST = loadable(
	() => import(/* webpackChunkName: "ListWidget" */ './list/ListWidget'),
	fallBack
);

/**
 * @function GRID
 * @description lazy loads Grid widget
 */
export const GRID = loadable(() => import(/* webpackChunkName: "Grid" */ './grid/Grid'), fallBack);

/**
 * @function EMBED
 * @description lazy loads Embed widget
 */
export const EMBED = loadable(
	() => import(/* webpackChunkName: "Embed" */ './embed/Embed'),
	fallBack
);

/**
 * @function HERO
 * @description lazy loads Banner Widget
 */
export const HERO = loadable(
	() => import(/* webpackChunkName: "BannerWidget" */ './hero/HeroWidget'),
	fallBack
);

/**
 * @function CAROUSEL
 * @description Carousel widget
 */
export const CAROUSEL = loadable(
	() => import(/* webpackChunkName: "Carousel" */ './carousel/Carousel'),
	fallBack
);

/**
 * @function VIDEO
 * @description Video widget
 */
export const VIDEO = loadable(
	() => import(/* webpackChunkName: "Video" */ './video/VideoWidget'),
	fallBack
);

/**
 * @function GALLERY
 * @description Photo gallery widget
 */
export const GALLERY = loadable(
	() => import(/* webpackChunkName: "Gallery" */ './gallery/Gallery'),
	fallBack
);

/**
 * @function SPONSORED_GALLERY
 * @description Photo gallery widget
 */
export const SPONSORED_GALLERY = loadable(
	() => import(/* webpackChunkName: "Gallery" */ './gallery/Gallery'),
	fallBack
);

/**
 * @function LIVESTREAM
 * @description Livestream widget
 */
export const LIVESTREAM = loadable(
	() => import(/* webpackChunkName: "Livestream" */ './livestream/LivestreamWidget'),
	fallBack
);

/**
 * @function GALLERY_GAME
 * @description Gallery Game widget
 */
export const GALLERY_GAME = loadable(
	() => import(/* webpackChunkName: "GalleryGame" */ './gallery-game/GameWidget'),
	fallBack
);
