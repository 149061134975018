import { useQuery } from '@apollo/client';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import CONTENT_LINKABLES from '@/graphql/queries/CONTENT_LINKABLES';
import { usePageContext } from '@/components/layout/page/PageContext';

/**
 * @function useContentQuery
 * @description uses contentLinkables query to get content feed data
 * @returns {Object}
 */
export default () => {
	const { uppercaseEdition } = usePageContext();
	const {
		categoryKey,
		contentTypes,
		currentPage,
		excludeBranded,
		limit
	} = useLandingPageContext();

	const skip = (currentPage - 1) * limit;

	const variables = {
		categoryKey,
		contentTypes,
		edition: uppercaseEdition,
		excludeBranded, 
		limit,
		skip
	};
	const response = useQuery(CONTENT_LINKABLES, { variables });

	if (response?.error) console.error(response.error.message);

	return response;
};
