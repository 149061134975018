import React from 'react';
import { object, array, string } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import parseHtml from '@/utils/parseHtml';
import intlUrl from '@/components/i18n/intlUrl';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { LandingPageContext } from '@/components/pages/landing/LandingPageContext';
import LandingHeader from './LandingHeader';
import Area from '@/components/widgets/Area';
import Content from '@/components/pages/landing/content/Content';
import LandingPageTaboola from './LandingPageTaboola';
import PageThemeProviderWrap from '@/components/page-theme/PageThemeProviderWrap';
import StructuredData from '@/components/structured-data/StructuredData';
import startCase from 'lodash-es/startCase';
import { APP_HOSTNAME } from '@/config/config';
import './LandingPage.scss';

/**
 * @function LandingPage
 * @description provides a Landing Page component
 * This component passes its props and some additional data into a context
 * @param {Object} [props.layout] from page object returned by API response
 * @param {Object} props.pageConfigData - from ./config/
 * @param {Array} [props.taboolaConfig] optional custom Taboola config to override base config
 * @returns {React.ReactElement}
 */
const LandingPage = ({ layout, pageConfigData, pageId, taboolaConfig }) => {
	const { edition, pageNum, uppercaseEdition, translations } = usePageContext();

	const sections = layout?.sections || [];
	const noData = sections.length < 1;
	const advertiser = layout?.adKeywords || 'null';

	// parse the currentPage number from the pageNum url param
	const currentPage = parseInt(pageNum) || 1;

	const {
		className,
		dataHook,
		metaTitle,
		showContentFeed,
		basePath,
		siteSection,
		categoryKey
	} = pageConfigData;

	// construct urlPrefix for pagination
	const urlPrefix = intlUrl(edition, `/${basePath}`);

	// Gather widget data
	const showWidgets = !noData && currentPage === 1;
	const parsedTitle = parseHtml(metaTitle);
	const context = {
		subTitle: `${metaTitle} - ${translations?.TITLE_SUFFIX || ''}`,
		advertiser,
		currentPage,
		excludeBranded: true,
		noData,
		sections,
		showWidgets,
		uppercaseEdition,
		urlPrefix,
		...pageConfigData,
		metaTitle:
			currentPage > 1
				? `${parsedTitle} - ${translations.PAGE_META} ${currentPage}`
				: `${parsedTitle}`
	};

	const isSiteSectionCategory = siteSection === 'category';
	const listItemId = isSiteSectionCategory ? categoryKey : siteSection;
	const homePageUrl = `https://${APP_HOSTNAME}${edition === 'us' ? '' : `/${edition}`}`;
	const { TABOOLA_STORIES_ENABLED } = useFeatureToggleContext();
	const { TABOOLA_STORIES_ENABLED_PARAM } = useParamToggleContext();
	const taboolaStoriesType =
		siteSection === 'news'
			? 'Stories'
			: siteSection === 'category'
			? categoryKey === 'shop'
				? 'stories-shop'
				: categoryKey === 'e_style_collective_instagram'
				? 'stories-esc-ig'
				: categoryKey === 'e_news_instagram'
				? 'stories-enews-ig'
				: null
			: null;

	return (
		<PageThemeProviderWrap id={pageId} siteSection={siteSection}>
			<LandingPageContext.Provider value={context}>
				<Helmet>
					<title>{context.metaTitle}</title>
				</Helmet>
				<div className={className} data-hook={dataHook}>
					<LandingHeader />

					{/* Taboola Stories div for 'news' & select 'category' landing pages */}
					{taboolaStoriesType &&
					(TABOOLA_STORIES_ENABLED || TABOOLA_STORIES_ENABLED_PARAM) ? (
						<div id={taboolaStoriesType.toLowerCase()} />
					) : null}

					{currentPage === 1 && <Area sections={sections} />}
					{showContentFeed && <Content />}
				</div>

				<LandingPageTaboola
					taboolaConfig={taboolaConfig}
					taboolaStoriesType={taboolaStoriesType}
				/>

				{pageConfigData.siteSection !== 'home' ? (
					<StructuredData
						data={{
							'@type': 'BreadcrumbList',
							itemListElement: [
								{
									'@type': 'ListItem',
									position: 1,
									item: {
										'@id': homePageUrl,
										name: 'Home'
									}
								},
								{
									'@type': 'ListItem',
									position: 2,
									item: {
										'@id': `${homePageUrl}/${
											isSiteSectionCategory ? 'news' : listItemId
										}`,
										name: isSiteSectionCategory ? 'News' : startCase(listItemId)
									}
								},
								...(isSiteSectionCategory
									? [
											{
												'@type': 'ListItem',
												position: 3,
												item: {
													'@id': `${homePageUrl}/news/${listItemId}`,
													name: startCase(listItemId)
												}
											}
									  ]
									: [])
							]
						}}
					/>
				) : null}
			</LandingPageContext.Provider>
		</PageThemeProviderWrap>
	);
};

LandingPage.defaultProps = {
	layout: {}
};

LandingPage.propTypes = {
	layout: object,
	pageConfigData: object.isRequired,
	pageId: string,
	taboolaConfig: array
};

LandingPage.displayName = 'LandingPage';

export default LandingPage;
