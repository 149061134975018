import React from 'react';
import Translate from '@/components/i18n/Translate';

/**
 * @function Loading
 * @description use as loading state while apollo is running queries/mutations
 * @returns {React.ReactElement}
 */
const Loading = () => {
	// TODO: Implement a better loading indicator with animation and visual flair
	return (
		<div data-testid="loading">
			<Translate word="LOADING" />
			...
		</div>
	);
};

Loading.displayName = 'Loading';

export default Loading;
