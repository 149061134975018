import React, { Fragment, useEffect } from 'react';
import LandingPage from '@/components/pages/landing/LandingPage';
import useLandingPageQuery from './useLandingPageQuery';
import { usePageContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import pageConfigData from '@/components/pages/landing/config/homeData';
import { MpsDataConfig } from '@/components/mps/MpsConfig';
import MpsAd from '@/components/mps/MpsAd';
import HrefLang from '@/components/i18n/hreflang/HrefLang';
import MetaTags from '@/components/meta-tags/MetaTags';
import HomeStructuredData from './HomeStructuredData';
import { newsroomHome } from '@/components/taboola/taboola-scripts';

const { siteSection } = pageConfigData;
const homeTaboolaInitData = [
	{
		placementName: 'Below Front Door Thumbnails v2',
		mode: 'thumbnails-feed-g',
		instancesToGenerate: 1
	}
];

/**
 * @function Home
 * @description provides a Home component
 * @returns {React.ReactElement}
 */
const Home = () => {
	const { THIRD_PARTY_ENABLED, TABOOLA_ENABLED } = useParamToggleContext();
	const { data } = useLandingPageQuery(siteSection);
	const { uppercaseEdition, translations } = usePageContext();

	const disableTaboola =
		uppercaseEdition === 'CA' || THIRD_PARTY_ENABLED === false || TABOOLA_ENABLED === false;

	const title = data?.page?.metaTitleOverride || translations.HOME_META_TITLE;
	const description = data?.page?.metaDescriptionOverride || translations.META_COMMON_DESC;

	const metaTagData = {
		title,
		description,
		ogType: 'website',
		options: { ogTags: true }
	};

	const configData = {
		...pageConfigData,
		metaTitle: title
	};

	useEffect(() => {
		if (!disableTaboola) newsroomHome();
	}, [disableTaboola]);

	return (
		<Fragment>
			<HrefLang uri="/" />
			<MpsDataConfig
				topCategory="front door"
				pageAdKeyword={data?.page?.layout?.adKeywords}
			/>
			<MetaTags {...metaTagData} />
			<HomeStructuredData />
			<LandingPage
				layout={data?.page?.layout}
				pageConfigData={configData}
				pageId={data?.page?.id}
				taboolaConfig={homeTaboolaInitData}
			/>
			{uppercaseEdition === 'US' && (
				<div className="theme-full-width">
					<MpsAd id="kargo" slot="kargo" className="mps-kargo" />
				</div>
			)}
		</Fragment>
	);
};

Home.displayName = 'Home';

export default Home;
