import React, { Fragment } from 'react';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import { buildAnalyticsObject } from './landingPageHelpers';
import DataLayer from '@/components/analytics/DataLayer';
import TitleHeader from '@/components/title-header/TitleHeader';
import NielsenStaticDCR from '@/components/nielsen/NielsenStaticDCR';
import { usePageContext } from '@/components/layout/page/PageContext';

/**
 * @function LandingHeader
 * @return {React.ReactElement}
 */
const LandingHeader = () => {
	const context = useLandingPageContext() || {};
	const { thumbnail, title, subTitle } = context;
	const pageviewData = buildAnalyticsObject(context);
	const { uppercaseEdition } = usePageContext();

	const nielsenAssetId = !context.analyticsCategory
		? 'homepage'
		: context.location === 'category-landing'
		? `${context.analyticsCategory}`
		: context.location;

	return (
		<Fragment>
			<DataLayer pageData={pageviewData} />
			<NielsenStaticDCR
				assetId={`${nielsenAssetId}_${uppercaseEdition}`}
				siteSection={`${nielsenAssetId}_${uppercaseEdition}`}
			/>
			<TitleHeader
				thumbnail={thumbnail}
				title={title}
				subTitle={subTitle}
				styleType="SECONDARY"
			/>
		</Fragment>
	);
};

LandingHeader.displayName = 'LandingHeader';

export default LandingHeader;
