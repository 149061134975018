import React, { Fragment, useEffect, useMemo } from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { loadTaboola } from './taboola-scripts';
import { withErrorBoundary } from '../error/ErrorBoundary';
import { string, array, bool } from 'prop-types';

// Please see docs/TABOOLA.md for more information about how to use this module.

const generateContainerName = (placementName) =>
	placementName.toLocaleLowerCase().replace(/ /g, '-');

/**
 * @function Taboola
 * @description Component for initializing and rendering Taboola modules
 * @param {Object} props
 * @param {String} props.pageName The name of the page used by Taboola for tracking purposes
 * @param {Array} props.initData Data for each Taboola placement being rendered
 * @param {Boolean} [props.generateContainers] Whether to generate Taboola container elements for each placement
 * @param {Boolean} [props.isOnLoad] Whether to initialize taboola on pg load. Disabled by default for performance reasons
 * @returns {React.ReactElement}
 */
const Taboola = ({ pageName, initData, generateContainers, isOnLoad }) => {
	const { edition } = usePageContext();
	const { THIRD_PARTY_ENABLED, TABOOLA_ENABLED } = useParamToggleContext();

	const disableTaboola =
		edition === 'ca' || THIRD_PARTY_ENABLED === false || TABOOLA_ENABLED === false;

	const taboolaConfigs = useMemo(() => [], []);
	const containerNames = [];

	useEffect(() => {
		if (disableTaboola === false) {
			if (isOnLoad) {
				loadTaboola(pageName);
			} else {
				// Defer loading until scroll
				const scroll = () => {
					loadTaboola(pageName);
					window.removeEventListener('scroll', scroll);
				};
				window.addEventListener('scroll', scroll, { passive: true });
			}
			window._taboola = window._taboola || [];

			taboolaConfigs.forEach((taboolaConfig) => _taboola.push(taboolaConfig));
		}
	}, [disableTaboola, isOnLoad, pageName, taboolaConfigs]);

	if (disableTaboola || !pageName || !initData) return null;

	// Render each Taboola placement
	initData.forEach((item) => {
		// Check for props
		if (
			typeof item.placementName === 'undefined' ||
			typeof item.mode === 'undefined' ||
			typeof item.instancesToGenerate === 'undefined' ||
			item.instancesToGenerate <= 0
		)
			return null;

		for (let i = 1; i <= item.instancesToGenerate; i++) {
			/* first item doesn't need a number postfix (1) */
			const containerNamePostfixNum = i === 1 ? '' : `-${i}`;
			const placementNamePostfixNum = i === 1 ? '' : ` ${i}`;
			const containerName = `${generateContainerName(
				item.placementName
			)}${containerNamePostfixNum}`;
			const placementName = `${item.placementName}${placementNamePostfixNum}`;

			containerNames.push({ containerName, placementName });

			/* only for browser... */
			if (typeof window !== 'undefined') {
				const initResult = (() => {
					if (typeof document === 'undefined') {
						return false;
					}

					taboolaConfigs.push({
						mode: item.mode,
						container: containerName,
						placement: placementName,
						target_type: 'mix'
					});

					return true;
				})();

				if (initResult === false) console.error('Missing Taboola api or config');
			}
		}
	});

	return (
		<Fragment>
			{generateContainers
				? containerNames.map((value, index) => {
						return (
							<div
								data-hook="taboola-wrap"
								id={value?.containerName}
								key={`${value}-${index}`}
								data-placement={value?.placementName}
							></div>
						);
				  })
				: null}
		</Fragment>
	);
};

Taboola.defaultProps = {
	generateContainers: false,
	isOnLoad: false
};

Taboola.propTypes = {
	pageName: string.isRequired,
	initData: array.isRequired,
	generateContainers: bool,
	isOnLoad: bool
};

Taboola.displayName = 'Taboola';

export default withErrorBoundary(Taboola);
