import { gql } from '@apollo/client';

/**
 * @const FragmentGalleryData
 * @description GraphQL fragment on Article implementation of LinkList interface.
 */
const FragmentGalleryData = gql`
	fragment GalleryData on Gallery {
		id
		uri(edition: $edition)
		branding {
			brandingType
			displayText
			ads {
				adKeywords
			}
			adTracking {
				advertiser
			}
		}
		publishDate
		firstPublishDate
		title
		thumbnail(edition: $edition) {
			id
			title
			uri
			sourceWidth
			sourceHeight
			agency
		}
		shortTitle
		snipe
		additionalContentLink(edition: $edition) {
			title
			uri(edition: $edition)
			thumbnail(edition: $edition) {
				uri
				title
				sourceWidth
				sourceHeight
			}
		}
	}
`;

export default FragmentGalleryData;
