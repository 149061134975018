/**
 * @const homeData
 * @returns {Object} homeData
 * @property {String} analyticsCategory - the content list's categoryKey
 * @property {String} analyticsContentType - 'news' or 'shows'
 * @property {String} basePath - path without protocol, edition, pagination, or trailing or leading slashes
 * @property {String} className - for main LandingPage element on page
 * @property {String} dataHook - for main LandingPage element on page
 * @property {String} metaTitle - for page's <title> tag
 * @property {String} analyticsPageType - for window.dataLayer / pageview tracking
 * @property {Boolean} showContentFeed
 * @property {String} siteSection - for window.dataLayer / pageview tracking
 * @property {String} title - for <h1>
 */
export default {
	analyticsCategory: '',
	analyticsContentType: '',
	basePath: '',
	className: 'home',
	dataHook: 'home',
	metaTitle: 'Homepage',
	analyticsPageType: 'landing',
	showContentFeed: false,
	siteSection: 'home',
	title: ''
};
