import React from 'react';
import { number, string } from 'prop-types';
import SvgImage from '@/components/icons/SvgImage';
import ChevronSvg from '@/images/chevron.svg';
import { getNextLink, getPrevLink } from './paginationUtils';
import { usePageContext } from '@/components/layout/page/PageContext';
import intlUrl from '@/components/i18n/intlUrl';
import './Pagination.scss';

/**
 * @function PaginationChevron
 * @description provides a div containing a chevron svg that may or may not be rotated
 * @returns {React.ReactElement}
 */
const PaginationChevron = () => (
	<div className="pagination__chevron">
		<SvgImage className="pagination__chevron-svg" image={ChevronSvg} />
	</div>
);

PaginationChevron.displayName = 'PaginationChevron';

/**
 * @function Pagination
 * @param {string} [props.analyticsLinkLocation]
 * @param {Number} props.currentPage
 * @param {String|Number} props.limit
 * @param {string} [props.nextLinkOverrideForLastPage] overrides the next link on the last page - instead of it being automatically greyed out/disabled, it will remain active with a override link value
 * @param {String} [props.nextText] overrides the "NEXT" text
 * @param {String} [props.prevText] overrides the "PREV" text
 * @param {String|Number} [props.totalCount]
 * @param {String} props.urlPrefix
 * @returns {React.ReactElement}
 */
const Pagination = ({
	analyticsLinkLocation,
	currentPage,
	limit,
	nextLinkOverrideForLastPage,
	nextText,
	prevText,
	totalCount,
	urlPrefix
}) => {
	if (!totalCount) return null;

	const { edition, translations } = usePageContext();
	const lastPage = Math.ceil(parseInt(totalCount) / parseInt(limit));
	const prevLink = getPrevLink(currentPage, urlPrefix);
	let nextLink = getNextLink(currentPage, lastPage, urlPrefix);
	const prevTextWithTrans = translations.PAGINATION_PREV || prevText;
	const nextTextWithTrans = translations.PAGINATION_NEXT || nextText;

	/* OVERRIDE OPTION: once next link is null (meaning you are on 
		last page) and if there is a next link override for last page, 
		then use that as the next link instead of a disabled next 
		button. Otherwise, just use the default disabled next button */
	if (nextLink === null && nextLinkOverrideForLastPage) nextLink = nextLinkOverrideForLastPage;

	// TODO: Add translated accessibility attributes based on edition

	return (
		<nav
			role="navigation"
			aria-label="Pagination Navigation"
			className="pagination"
			data-hook="pagination"
		>
			<div className="pagination__container">
				{currentPage >= 3 && (
					<a
						aria-label="Goto First Page"
						className="pagination__double-chevron-link pagination__link"
						href={intlUrl(edition, urlPrefix)}
						data-hook="double-previous-pagination"
						data-analytics-linkname="previous-pagination"
						data-analytics-linklocation={analyticsLinkLocation}
						title="First Page"
					>
						<PaginationChevron />
						<PaginationChevron />
					</a>
				)}
				{prevLink ? (
					<a
						data-analytics-linkname="previous-pagination"
						data-analytics-linklocation={analyticsLinkLocation}
						data-hook="previous-pagination"
						data-testid="previous-pagination"
						aria-label="Goto Previous Page"
						href={intlUrl(edition, prevLink)}
						className="pagination__link"
						title="Previous Page"
					>
						<PaginationChevron />
						<span className="pagination__text">{prevTextWithTrans}</span>
					</a>
				) : (
					<span
						aria-label="Current Page, First Page"
						aria-current="true"
						data-testid="previous-pagination"
						className="pagination__link pagination__link--disabled"
					>
						<PaginationChevron />
						<span className="pagination__text">{prevTextWithTrans}</span>
					</span>
				)}
				<div className="pagination__current-page-number has-text-centered">
					{currentPage}
				</div>
				{nextLink ? (
					<a
						data-analytics-linkname="next-pagination"
						data-analytics-linklocation={analyticsLinkLocation}
						data-hook="next-pagination"
						data-testid="next-pagination"
						aria-label="Goto Next Page"
						href={intlUrl(edition, nextLink)}
						className="pagination__link"
						title="Next Page"
					>
						<span className="pagination__text">{nextTextWithTrans}</span>
						<PaginationChevron />
					</a>
				) : (
					<span
						aria-label="Current Page, Last Page"
						aria-current="true"
						data-testid="next-pagination"
						className="pagination__link pagination__link--disabled"
					>
						<span className="pagination__text">{nextTextWithTrans}</span>
						<PaginationChevron />
					</span>
				)}
			</div>
		</nav>
	);
};

Pagination.defaultProps = {
	nextLinkOverrideForLastPage: null
};

Pagination.propTypes = {
	analyticsLinkLocation: string,
	currentPage: number.isRequired,
	limit: number.isRequired,
	nextLinkOverrideForLastPage: string,
	nextText: string,
	prevText: string,
	totalCount: number,
	urlPrefix: string.isRequired
};

Pagination.displayName = 'Pagination';

export default Pagination;
