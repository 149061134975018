import React, { Fragment } from 'react';
import { object } from 'prop-types';

/**
 * @function NoWrap
 * @description Used to just pass in the children and perform a 'no wrap' element
 * @param {Object} children
 * @return {React.ReactElement}
 */
const NoWrap = ({ children }) => <Fragment>{children}</Fragment>;

NoWrap.propTypes = {
	children: object
};

NoWrap.displayName = 'NoWrap';

export default NoWrap;
