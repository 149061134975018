/**
 * @const baseLandingPageTaboolaConfig
 */
export const baseLandingPageTaboolaConfig = [
	{
		placementName: 'Below Landing Page Thumbnails v2',
		mode: 'thumbnails-feed-g',
		instancesToGenerate: 1
	}
];

/**
 * @const rightRailTaboolaConfig
 */
export const rightRailTaboolaConfig = {
	placementName: 'Right Rail Landing Page Thumbnails',
	mode: 'thumbs-feed-01-rr-v2',
	instancesToGenerate: 1
};

/**
 * @const taboolaStoriesConfig
 * @param {String} placementName
 * @returns {Object}
 */
export const taboolaStoriesConfig = (placementName = 'Stories') => ({
	placementName,
	mode: 'thumbnails-stories',
	instancesToGenerate: 1
});
