import { gql } from '@apollo/client';

export default gql`
	query TRENDING($edition: EDITIONKEY!, $limit: Limit) {
		trendingContent(filter: { contentType: ARTICLE, edition: $edition, limit: $limit }) {
			id
			title
			shortTitle
			snipe
			uri(edition: $edition)
			thumbnail(edition: $edition) {
				id
				uri
				sourceHeight
				sourceWidth
			}
			type
		}
	}
`;
