import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { any, string } from 'prop-types';
import { PageThemeContext } from './PageThemeContext';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { IS_PREVIEW_MODE } from '@/config/config';
import usePreviewThemeQuery from './usePreviewThemeQuery';
import useSiteThemeQuery from './useSiteThemeQuery';

// TEMP for Taboola testing
const testTheme = {
	siteTheme: {
		activeDate: null,
		expireDate: null,
		hotTopicsColor: '#C84AC6',
		hoverColor: '#C84AC6',
		logoColor: '#C84AC6',
		navHoverColor: '#C84AC6',
		notificationBarColor: '#C84AC6',
		pageBackgroundColor: '#394395',
		pageBackgroundImage: {
			uri:
				'https://akns-images.eonline.com/eol_images/Entire_Site/20201012/rs_1920x1200-201112150205-rs_1920x1200-201104120423-1920-epca-background.jpg'
		},
		pageTitleColor: '#ffffff',
		primaryColor: '#C84AC6',
		secondaryColor: '#1DA2E7',
		widgetTheme: 'BLACK'
	}
};

// Used for theming Taboola content block
// should correspond to --widget-title-color & --widget-bkg-color in theming.scss
const widgetColors = {
	WHITE: {
		title: '#1e1e1e', // $gray-5
		bkg: '#ffffff' // $white
	},
	GREY: {
		title: '#1e1e1e', // $gray-5
		bkg: '#f0f0f0' // $gray-0
	},
	BLACK: {
		title: '#f7f7f7', // $gray-1
		bkg: '#000000' // $black
	}
};

/**
 * @function PageThemeProviderWrap
 * @description Context wrap for page theme; inserts page theme styles
 * @param {Object} props
 * @param {String} [props.id]
 * @param {String} [props.siteSection]
 * @param {Any} props.children
 * @param {React.ReactElement} props.children
 * @return {React.ReactElement}
 */
const PageThemeProviderWrap = ({ id, siteSection, children }) => {
	const { themeId } = usePageContext();
	const { TABOOLA_THEME_ENABLED } = useFeatureToggleContext();
	const { THEMED_PAGE } = useParamToggleContext();
	const [now, setNow] = useState(new Date());
	const isPreview = !!IS_PREVIEW_MODE && !!themeId;

	useEffect(() => {
		// double checks we're getting the correct date relative to the user
		const today = new Date();
		setNow(today);
	}, []);

	// find "skip" boolean values so we're only running one query
	const skipPreviewTheme = !isPreview || !themeId || THEMED_PAGE; // skip if not in preview mode
	const skipSiteTheme = !id || isPreview || THEMED_PAGE; // skip if no id or in preview mode
	const previewThemeResult = usePreviewThemeQuery(themeId, skipPreviewTheme);
	const siteThemeResult = useSiteThemeQuery(skipSiteTheme);
	// set data to available theme data returned
	const data = THEMED_PAGE ? testTheme : previewThemeResult || siteThemeResult || null;
	// normalize naming since preview and siteTheme queries return differnt structure names
	const siteTheme = data?.siteTheme || data?.theme || {};
	// mark as a themed page if it matches in the theme pageIds list
	const isThemePage = siteTheme?.pageIds?.length && siteTheme?.pageIds.includes(id);
	// check if this is a live theme
	const activeDate = siteTheme?.activeDate && new Date(siteTheme.activeDate);
	const expireDate = siteTheme?.expireDate && new Date(siteTheme.expireDate);
	const isValidDate =
		(!activeDate || activeDate <= now) && ((expireDate && expireDate > now) || !expireDate);
	// set if the theme should show now on this page
	const hasTheme = isPreview || THEMED_PAGE || (isThemePage && isValidDate);
	// set context value for widget theming
	const pageTheme = hasTheme ? siteTheme?.widgetTheme : null;
	// flag if a dark theme since it can follow design different rules
	const isDarkTheme = siteTheme?.widgetTheme === 'BLACK';

	return (
		<PageThemeContext.Provider value={{ pageTheme }}>
			{hasTheme && (
				<Helmet>
					{(TABOOLA_THEME_ENABLED || THEMED_PAGE) && siteSection === 'home' && (
						<style type="text/css">{`
							:root {
								--taboola-txt-color: ${widgetColors[siteTheme.widgetTheme]?.title};
								--taboola-bkg-color: ${widgetColors[siteTheme.widgetTheme]?.bkg};
								${siteTheme.hoverColor ? `--taboola-hover-color: ${siteTheme.hoverColor};` : ''}
							}
						`}</style>
					)}
					{(siteTheme.pageBackgroundColor || siteTheme.pageBackgroundImage?.uri) && (
						<style type="text/css">{`
							
							.theme-wrap .theme-full-width {
								background-color: #ffffff;
								margin-left: -2rem;
								margin-right: -2rem;
							}

							.theme-wrap .theme-full-width > div {
								margin-left: 2rem;
								margin-right: 2rem;
							}
							.theme-wrap .theme-full-width > section {
								margin-left: 2rem;
								margin-right: 2rem;
							}
							
							@media screen and (min-width: 1024px) {
								.theme-wrap .theme-full-width {
									margin-left: calc(-100vw / 2 + 960px / 2);
									margin-right: calc(-100vw / 2 + 960px / 2);
								}
								.theme-wrap .theme-full-width > div {
									max-width: 960px;
									margin: auto;
								}
								.theme-wrap .theme-full-width > section {
									max-width: 960px;
									margin: auto;
								}
							}
							
							@media screen and (min-width: 1216px) {
								.theme-wrap .theme-full-width {
									margin-left: calc(-100vw / 2 + 1152px / 2);
									margin-right: calc(-100vw / 2 + 1152px / 2);
								}
								.theme-wrap .theme-full-width > div {
									max-width: 1152px;
									margin: auto;
								}
								.theme-wrap .theme-full-width > section {
									max-width: 1152px;
									margin: auto;
								}
							}
							
							@media screen and (min-width: 1408px) {
								.theme-wrap .theme-full-width {
									margin-left: calc(-100vw / 2 + 1344px / 2);
									margin-right: calc(-100vw / 2 + 1344px / 2);
								}
								.theme-wrap .theme-full-width > div {
									max-width: 1344px;
									margin: auto;
								}
								.theme-wrap .theme-full-width > section {
									max-width: 1344px;
									margin: auto;
								}
							}						
						`}</style>
					)}
					{siteTheme.pageBackgroundColor && !siteTheme.pageBackgroundImage?.uri && (
						<style type="text/css">{`
							.theme-wrap {
								background-color: ${siteTheme.pageBackgroundColor};
							}
						`}</style>
					)}
					{siteTheme.pageBackgroundImage?.uri && (
						<style type="text/css">{`
							.theme-wrap {
								background: 
									${siteTheme.pageBackgroundColor ? siteTheme.pageBackgroundColor : ''} 
									url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|960:1200o&output-quality=90&crop=960:1200;center,top) repeat-y top center fixed;
								background-size: 480px 600px;
							}
	
							@media screen and (min-width: 481px) {
								.theme-wrap {
									background-image: url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|769:1200o&output-quality=90&crop=769:1200;center,top);
									background-size: auto;
								}
							}
	
							@media screen and (min-width: 758px) {
								.theme-wrap {
									background-image: url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|1024:1200&output-quality=90&crop=1024:1200;center,top);
									background-repeat: no-repeat;
								}
							}
	
							@media screen and (min-width: 1024px) {
								.theme-wrap {
									background-image: url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|1216:1200&output-quality=90&crop=1216:1200;center,top);
								}
							}
	
							@media screen and (min-width: 1216px) {
								.theme-wrap {
									background-image: url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|1408:1200&output-quality=90&crop=1408:1200;center,top);
								}
							}
	
							@media screen and (min-width: 1408px) {
								.theme-wrap {
									background-image: url(${
										siteTheme.pageBackgroundImage.uri
									}?fit=around|1920:1200&output-quality=95&crop=1920:1200;center,top);
								}
							}
						`}</style>
					)}
					{siteTheme.logoColor && (
						<style type="text/css">{`
							.navigation {
								--navbar-logo-color: ${siteTheme.logoColor};
								--alt-navbar-logo-color: ${siteTheme.logoColor};
							}
						`}</style>
					)}
					{siteTheme.notificationBarColor && (
						<style type="text/css">{`
							.navigation {
								--notification-bar-color: ${siteTheme.notificationBarColor};
							}
						`}</style>
					)}
					{siteTheme.navHoverColor && (
						<style type="text/css">{`
							.navigation {
								--navbar-hover-color: ${siteTheme.navHoverColor};
							}
						`}</style>
					)}
					{siteTheme.primaryColor && (
						<style type="text/css">{`
							.widget {
								--widget-header-color: ${siteTheme.primaryColor};
								--widget-header-border-color: ${siteTheme.primaryColor};
								--numbered-list-border-color: ${siteTheme.primaryColor};
								--snipe-bkg-color: ${siteTheme.primaryColor};
								${
									isDarkTheme
										? `--snipe-inverted-bkg-color: ${siteTheme.primaryColor};
								--snipe-inverted-text-color: #fff;`
										: `--snipe-inverted-text-color: ${siteTheme.primaryColor};`
								}
							}
						`}</style>
					)}
					{siteTheme.secondaryColor && (
						<style type="text/css">{`
							.widget {
								--caption-bkg-color: ${siteTheme.secondaryColor};
								--snipe-banner-color: ${siteTheme.secondaryColor};
								--widget-link-color: ${siteTheme.secondaryColor};
								--content-icon-color: ${siteTheme.secondaryColor};
							}
						`}</style>
					)}
					{siteTheme.hoverColor && (
						<style type="text/css">{`
							.widget {
								--widget-hover-color: ${siteTheme.hoverColor};
								--widget-link-hover-color: ${siteTheme.hoverColor};
								--widget-header-hover-color: ${siteTheme.hoverColor};
								--now-playing-overlay-color: ${siteTheme.hoverColor};
								--widget-arrow-icon-hover-color: ${siteTheme.hoverColor};
							}
							.widget a:hover {
								color: ${siteTheme.hoverColor};
							}
						`}</style>
					)}
					{siteTheme.pageTitleColor && (
						<style type="text/css">{`
							.title-header {
								--page-title-secondary-color: ${siteTheme.pageTitleColor};
								--page-title-secondary-line-color: ${siteTheme.pageTitleColor};
							}
						`}</style>
					)}
				</Helmet>
			)}
			{children}
		</PageThemeContext.Provider>
	);
};

PageThemeProviderWrap.propTypes = {
	id: string,
	siteSection: string,
	children: any.isRequired
};

PageThemeProviderWrap.displayName = 'PageThemeProviderWrap';

export default PageThemeProviderWrap;
