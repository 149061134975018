import removeDuplicates from '@/utils/removeDuplicates';
import stripTrailingSlash from '@/utils/stripTrailingSlash';

/**
 * @function getWidgetTypes
 * @private
 * @description parses widgets array and returns formatted widgetTypes
 * @param {*} widgets
 * @returns {Array}
 */
const getWidgetTypes = (widgets) =>
	widgets.map((widget) => `${widget?.widgetType?.toLowerCase()}_widget`);

/**
 * @function buildPagewidgetType
 * @private
 * @description Parses sections data from Page query response to build pagewidgetType for analytic tracking
 * @param {Array} sections
 * @param {Boolean} showContentFeed
 * @returns {String}
 */
const buildPagewidgetType = (sections, showContentFeed) => {
	const pagewidgetTypeArr = sections
		.reduce((acc, section) => acc.concat(...getWidgetTypes(section.widgets)), [])
		.filter(removeDuplicates);

	if (showContentFeed) pagewidgetTypeArr.push('content_feed');

	return pagewidgetTypeArr.join(',');
};

// EXPORTS

/**
 * @function buildAnalyticsObject
 * @param {Object} Object
 * @param {String} Object.advertiser
 * @param {String} Object.analyticsCategory
 * @param {String} Object.analyticsContentType
 * @param {String} [Object.analyticsPageType]
 * @param {String} Object.metaTitle
 * @param {String} Object.pageType - defaults to 'landing'
 * @param {Array} Object.sections - defaults to empty array
 * @param {Boolean} Object.showContentFeed - defaults to true
 * @param {String} Object.siteSection
 * @param {String} Object.urlPrefix
 * @returns {Object}
 */
export const buildAnalyticsObject = ({
	advertiser,
	analyticsCategory,
	analyticsContentType,
	analyticsPageType = 'landing',
	sections = [],
	showContentFeed = true,
	siteSection,
	urlPrefix,
	metaTitle
}) => ({
	contentInfo: {
		title: metaTitle ? metaTitle.toLowerCase().replace(' ', '-') : '',
		originalUrl: stripTrailingSlash(`www.eonline.com${urlPrefix}`),
		categories: analyticsCategory,
		contentType: analyticsContentType,
		pagewidgetType: buildPagewidgetType(sections, showContentFeed)
	},
	pageInfo: {
		pageType: analyticsPageType,
		siteSection
	},
	sponsoredInfo: { advertiser }
});
