import React from 'react';
import { object, string } from 'prop-types';
import Thumb from '@/components/thumb/Thumb';
import TimeElapsed from '@/components/date/TimeElapsed';
import WidgetTitle from '@/components/widgets/WidgetTitle';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import WidgetDescription from '@/components/widgets/WidgetDescription';
import SnipeBanner from '@/components/snipe-banner/SnipeBanner';
import './ContentItem.scss';

/**
 * @function ContentItem
 * @description provides a single content item for a Content Feed, includes thumbnail and text
 * @param {Object} props.item
 * @param {String} [props.className]
 * @returns {React.ReactElement}
 */
const ContentItem = ({ item, className }) => {
	const { id, publishDate, thumbnail, title, type, uri, firstPublishDate } = item;
	let { snipe } = item;
	const description = item.subhead || item.description || null;
	const { analyticsCategory, analyticsPageType } = useLandingPageContext();
	const contentItemAnalyticsData = {
		'data-analytics-feedcontenttype': type?.toLowerCase(),
		'data-analytics-linkname': `${analyticsCategory}-content-feed-items`,
		'data-analytics-linklocation': analyticsPageType
	};

	let timeDifferenceInHours;
	// Breaking banner should only appear for 12 hours
	if (snipe === 'BREAKING') {
		const givenDate = new Date(firstPublishDate);
		timeDifferenceInHours = (new Date() - givenDate) / (1000 * 60 * 60);
		if (timeDifferenceInHours > 12) {
			snipe = null;
		}
	}

	return (
		<div
			key={`content-item-${id}`}
			className={`content-item columns is-tablet ${className}`}
			data-hook="content-item"
		>
			{/* Column 1: Thumbnail and Media Icon */}
			<div className="content-item__thumb column is-4">
				<Thumb
					width="300"
					type="16x9"
					thumbnail={thumbnail}
					href={uri}
					videoId={type === 'VIDEO' ? id : null}
					analyticsData={contentItemAnalyticsData}
				>
					{snipe && (
						<SnipeBanner
							snipe={snipe}
							color="WHITE"
							className="is-hidden-tablet"
							inset={true}
						/>
					)}
					<ContentItemIcon type={type} />
				</Thumb>
			</div>

			{/* Column 2: Title / Published */}
			<div className="column content-item__text">
				{snipe && <SnipeBanner snipe={snipe} line={true} className="is-hidden-mobile" />}
				<WidgetTitle title={title} href={uri} analyticsData={contentItemAnalyticsData} />
				{description && <WidgetDescription text={description} />}
				{publishDate && (
					<p className="content-item__date">
						<TimeElapsed date={publishDate} />
					</p>
				)}
			</div>
		</div>
	);
};

ContentItem.defaultProps = {
	item: {},
	className: ''
};

ContentItem.propTypes = {
	item: object.isRequired,
	className: string
};

ContentItem.displayName = 'ContentItem';

export default ContentItem;
