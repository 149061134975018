import { gql } from '@apollo/client';

/**
 * @const FragmentVideoData
 * @description GraphQL fragment on Video implementation of LinkList interface.
 */
const FragmentVideoData = gql`
	fragment VideoData on Video {
		id
		publicId
		duration
		publishDate
		snipe
		shortTitle
		webVttUri
		uri(edition: $edition)
		videoUri
		thumbnail {
			id
			uri
			title
			sourceWidth
			sourceHeight
		}
		hdThumbnail {
			id
			uri
			title
			sourceWidth
			sourceHeight
		}
		branding {
			brandingType
			displayText
			ads {
				adKeywords
			}
			adTracking {
				advertiser
			}
		}
	}
`;

export default FragmentVideoData;
