import React from 'react';
import { array, object, oneOfType } from 'prop-types';

/**
 * @function Full
 * @param {Object} props
 * @param {Array} props.children
 * @return {React.ReactElement}
 */
const Full = ({ children }) => {
	return (
		<div className="columns">
			<div className="column is-full">{children[0]}</div>
		</div>
	);
};

Full.propTypes = {
	children: oneOfType([array, object]).isRequired
};

Full.displayName = 'Full';

export default Full;
