import React from 'react';
import { number } from 'prop-types';
import './ListCount.scss';

const ListCount = ({ count }) => (
	<div className="list-count column is-narrow">
		<div className="list-count__number">{count}</div>
	</div>
);

ListCount.propTypes = {
	count: number
};

ListCount.displayName = 'ListCount';

export default ListCount;
