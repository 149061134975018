import React from 'react';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import intlUrl from '@/components/i18n/intlUrl';
import { string, object, element, oneOfType, bool } from 'prop-types';
import GatedLink from '@/components/identity/GatedLink';
import './WidgetTitle.scss';

/**
 * @function WidgetTitle
 * @param {Object} props
 * @param {String} props.title
 * @param {String} [props.href] Pass if you want it to be a link
 * @param {Object} [props.analyticsData]
 * @param {String} [props.className]
 * @param {String} [props.className]
 * @param {Boolean} [props.isChildrenInsideTitle]
 * @param {*} rest use for things like data-hook or additional props that should be passed down
 * @return {React.ReactElement}
 */
const WidgetTitle = ({
	title,
	href,
	analyticsData,
	children,
	className,
	isChildrenInsideTitle,
	...rest
}) => {
	if (!title) return null;
	const { edition } = usePageContext();
	const parsedTitle = parseHtml(title);
	return href ? (
		<GatedLink
			className={`widget__title ${className}`}
			{...analyticsData}
			href={intlUrl(edition, href)}
			{...rest}
		>
			{!isChildrenInsideTitle && children}
			<h3>
				{isChildrenInsideTitle && children}
				{parsedTitle}
			</h3>
		</GatedLink>
	) : (
		<p className={`widget__title ${className}`} {...rest}>
			{!isChildrenInsideTitle && children}
			<h3>
				{isChildrenInsideTitle && children}
				{parsedTitle}
			</h3>
		</p>
	);
};

WidgetTitle.propTypes = {
	title: string.isRequired,
	href: string,
	uri: string,
	className: string,
	isChildrenInsideTitle: bool,
	analyticsData: object,
	children: oneOfType([element, bool])
};

WidgetTitle.defaultProps = {
	analyticsData: {},
	className: '',
	isChildrenInsideTitle: false
};

WidgetTitle.displayName = 'WidgetTitle';

export default WidgetTitle;
