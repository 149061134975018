/**
 * @function getLocaleString
 * @description provides a locale string for meta data
 * @param {String} edition
 * @returns {String}
 */
export default (edition) =>
	({
		au: 'en_AU',
		ap: 'en_AP',
		ca: 'en_CA',
		us: 'en_US',
		uk: 'en_UK',
		fr: 'fr_FR',
		de: 'de_DE',
		br: 'pt_BR',
		mx: 'es_MX',
		ar: 'es_AR',
		ve: 'es_VE',
		co: 'es_CO'
	}[edition] || 'en_US');
