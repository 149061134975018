import { gql } from '@apollo/client';

export default gql`
	query SITE_THEME($edition: EDITIONKEY!) {
		siteTheme(edition: $edition) {
			activeDate
			expireDate
			hotTopicsColor
			hoverColor
			navHoverColor
			id
			logoColor
			notificationBarColor
			pageBackgroundColor
			pageBackgroundImage {
				uri
			}
			pageIds
			primaryColor
			publishState
			secondaryColor
			pageTitleColor
			widgetTheme
		}
	}
`;
