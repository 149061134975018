import { createContext, useContext } from 'react';

export const LandingPageContext = createContext({
	advertiser: 'null',
	currentPage: 1,
	limit: 25,
	title: ''
});

export const useLandingPageContext = () => useContext(LandingPageContext);
