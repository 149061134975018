import React from 'react';
import { array } from 'prop-types';

/**
 * @function SixThreeThree
 * @param {Object} props
 * @param {Array} props.children
 * @return {React.ReactElement}
 */
const SixThreeThree = ({ children }) => {
	return (
		<div className="columns">
			<div className="column is-6-desktop is-full-tablet is-full-mobile">{children[0]}</div>
			<div className="column is-3-desktop is-half-tablet is-full-mobile">{children[1]}</div>
			<div className="column is-3-desktop is-half-tablet is-full-mobile">{children[2]}</div>
		</div>
	);
};

SixThreeThree.propTypes = {
	children: array.isRequired
};

SixThreeThree.displayName = 'SixThreeThree';

export default SixThreeThree;
