import format from 'date-fns/format';

/**
 * @function formatDate
 * @description formats dates for use with meta tags
 * @param {String} date
 * @returns {String}
 */
export const formatDate = (date) => {
	try {
		return format(new Date(date), 'eee MMM dd HH:mm:ss z yyyy');
	} catch (e) {
		return null;
	}
};

/**
 * @function formatCategories
 * @description formats categories for use with meta tags
 * @param {Array} categories
 * @returns {String}
 */
export const formatCategories = (categories) => {
	return categories
		? categories
				.filter(({ displayName }) => displayName)
				.map(({ displayName }) => displayName?.replace(/ /g, '_').toLowerCase())
				.join()
		: '';
};
