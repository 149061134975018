import React from 'react';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import Hero from '@/components/widgets/hero/Hero';
import SeeMore from '@/components/widgets/SeeMore';
import ListRow from '@/components/widgets/list/list-row/ListRow';
import intlUrl from '@/components/i18n/intlUrl';
import { usePageContext } from '@/components/layout/page/PageContext';
import { object, array, string, func } from 'prop-types';
import './List.scss';

/**
 * @function List
 * @param {Object} props
 * @param {Function} [props.analyticsData] function that returns click tracking data attributes and values
 * @param {String} [props.className]
 * @param {Array} props.contentItems
 * @param {Object} [props.header]
 * @param {Object} props.options
 * @param {Boolean} props.options.hideAuthors
 * @param {Boolean} [props.options.hideDate]
 * @param {Boolean} [props.options.hideSeeMore]
 * @param {Boolean} [props.options.showHero]
 * @param {String} [props.options.thumbType]
 * @param {String} [props.options.showThumbs]
 * @param {Boolean} [props.options.showCount]
 * @param {Boolean} [props.options.showThumbCount]
 * @param {String} props.sectionType
 * @param {Object} [props.seeMoreAnalyticsData]
 * @return {React.ReactElement}
 */
const List = ({
	analyticsData,
	className,
	contentItems,
	header,
	options,
	sectionType,
	seeMoreAnalyticsData
}) => {
	const { edition } = usePageContext();

	return (
		<section className={className || ''}>
			<WidgetHeader title={header?.title} thumbnail={header?.thumbnail} />
			<div className="list__content">
				{contentItems.map((item, index) => {
					const itemType = item?.type?.toLowerCase();
					const analyticsDataObj = analyticsData && analyticsData({ type: itemType });
					if (!item || !item.thumbnail) return null;
					return index === 0 && options.showHero ? (
						<Hero
							analyticsData={analyticsData}
							authors={item.authors}
							key={item.id}
							options={options}
							publishDate={item.publishDate}
							snipe={item.snipe}
							thumbnail={item.thumbnail}
							title={item.title}
							type={item.type}
							uri={item.uri}
							width="660"
						/>
					) : (
						<ListRow
							key={item.id}
							{...item}
							displayImage={item.thumbnail}
							count={index + 1}
							options={options}
							reference={item}
							analyticsData={analyticsDataObj}
						/>
					);
				})}
			</div>
			{!options.hideSeeMore && (
				<SeeMore link={intlUrl(edition, '/news')} analyticsData={seeMoreAnalyticsData} />
			)}
		</section>
	);
};

List.propTypes = {
	analyticsData: func,
	className: string,
	contentItems: array.isRequired,
	header: object,
	options: object,
	sectionType: string,
	seeMoreAnalyticsData: object
};

List.displayName = 'List';

export default List;
