import { gql } from '@apollo/client';
import FragmentArticleData from './FRAGMENT_ARTICLE_DATA';
import FragmentGalleryData from './FRAGMENT_GALLERY_DATA';
import FragmentVideoData from './FRAGMENT_VIDEO_DATA';
import FragmentGalleryFolderData from './FRAGMENT_GALLERY_FOLDER_DATA';

export default gql`
	${FragmentArticleData}
	${FragmentGalleryData}
	${FragmentVideoData}
	${FragmentGalleryFolderData}
	query LANDING_PAGE($id: ID!, $edition: EDITIONKEY!) {
		page(id: $id) {
			id
			name
			metaTitleOverride
			metaDescriptionOverride
			layout {
				adKeywords
				sections {
					id
					sectionType
					widgets {
						id
						name
						timestampsEnabled
						authorBylineEnabled
						header
						headerImage {
							id
							title
							uri
						}
						isCurated
						criteria {
							contentType
							category(edition: $edition) {
								displayName(edition: $edition)
								key
							}
							dataType
							videoFeedId
						}
						contentList(edition: $edition, excludeBranded: true) {
							displayTitle
							displayImage(edition: $edition) {
								id
								title
								uri
								sourceWidth
								sourceHeight
							}
							linkType
							reference {
								id
								uri(edition: $edition)
								...ArticleData
								...GalleryData
								...VideoData
								...GalleryFolderData
							}
						}
						livestream(edition: $edition) {
							akamaiStreamUrl
							autoPlayToggle
							streamTitle
							streamDescription
							autoPlayToggle
							graphicInput {
								id
								title
								uri
							}
						}
						embedHtml
						widgetType
						widgetTemplate
						brandedTextOverride
						advertiserLogoOverride
						widgetAdKeyword
						brandingType
						additionalContentLink {
							reference {
								uri(edition: $edition)
								title
							}
						}
						coverflowBackgroundImage {
							id
							uri
						}
					}
				}
				contentFeedEnabled
			}
		}
	}
`;
