import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { string } from 'prop-types';

// Nielsen DCR documentation:
// https://engineeringportal.nielsen.com/docs/DCR_Static_Browser_SDK_(6.0.0)

/**
 * @function NielsenStaticDCR
 * @param {String} assetId
 * @param {String} siteSection
 * @return {React.ReactElement}
 */

const NielsenStaticDCR = ({ assetId, siteSection }) => {
	const { THIRD_PARTY_ENABLED, NIELSEN_DCR } = useParamToggleContext();

	return THIRD_PARTY_ENABLED && NIELSEN_DCR ? (
		<Helmet>
			<script type="text/plain" class="optanon-category-8">{`
                // Static Queue Snippet
                ! function(t, n) {
                    t[n] = t[n] || {
                        nlsQ: function(e, o, c, r, s, i) {
                            return s = t.document, r = s.createElement("script"), r.async = 1, r.src =
                                ("http:" === t.location.protocol ? "http:" : "https:") + "//cdn-gl.imrworldwide.com/conf/" +
                                e + ".js#name=" + o + "&ns=" + n, i = s.getElementsByTagName("script")[0],
                                i.parentNode.insertBefore(r, i), t[n][o] = t[n][o] || {
                                    g: c || {},
                                    ggPM: function(e, c, r, s, i) {
                                        (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i])
                                    }
                                }, t[n][o]}}}
                (window, "NOLBUNDLE");

                // SDK Initialization (for logs set nol_sdkDebug: "debug")
                var nSdkInstance = NOLBUNDLE.nlsQ("P7B7DDF6C-EBA7-42BE-BEF2-232D0A48C49A", "nlsnInstance", {
                    nol_sdkDebug: "false"
                });
                
                // Content Metadata 
                var nielsenMetadata = {
                    type: 'static', 
                    assetid: '${assetId}',
                    section: '${siteSection}',
                    }; 
                    
                // Event 'staticstart' Call
                nSdkInstance.ggPM("staticstart", nielsenMetadata);
            `}</script>
		</Helmet>
	) : null;
};

NielsenStaticDCR.displayName = 'NielsenStaticDCR';

NielsenStaticDCR.defaultProps = {
	assetId: '',
	siteSection: ''
};

NielsenStaticDCR.propTypes = {
	assetId: string.isRequired,
	siteSection: string.isRequired
};

export default NielsenStaticDCR;
