import React from 'react';
import { array } from 'prop-types';
import './WidgetAuthor.scss';

/**
 * @function WidgetAuthor
 * @param {Object} props
 * @param {Array|null} [props.authors]
 * @return {React.ReactElement}
 */
const WidgetAuthor = ({ authors }) => {
	return authors?.[0]?.fullName ? <p className="widget__author">{authors[0].fullName}</p> : null;
};

WidgetAuthor.propTypes = {
	authors: array
};

WidgetAuthor.displayName = 'WidgetAuthor';

export default WidgetAuthor;
