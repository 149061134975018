import { createContext, useContext } from 'react';

// Page Theme context
export const PageThemeContext = createContext({
	pageTheme: null // page override for widget theme type
});

/**
 * @function usePageThemeContext
 * @description provides PageThemeContext data when instantiated
 * @returns {Object}
 */
export const usePageThemeContext = () => useContext(PageThemeContext);
