import { useQuery } from '@apollo/client';
import THEME from '@/graphql/queries/THEME';

/**
 * @function usePreviewThemeQuery
 * @param {String} themeId
 * @param {Boolean} skip if set to true the query will be skipped
 * @returns {Object}
 */
export default (themeId, skip) => {
	const { data, error } = useQuery(THEME, { variables: { id: themeId }, skip });

	if (error) {
		console.log('Preview theme error: ' + error);
		return null;
	} else {
		return data;
	}
};
