import { useContext } from 'react';
import { PageContext } from '@/components/layout/page/PageContext';
import { differenceInHours, format, formatDistanceToNow, parseISO } from 'date-fns';
import dateFnsDe from 'date-fns/locale/de';
import dateFnsFr from 'date-fns/locale/fr';
import dateFnsEs from 'date-fns/locale/es';
import dateFnsPt from 'date-fns/locale/pt';
import { string } from 'prop-types';

const locales = {
	EN: null,
	ES: dateFnsEs,
	FR: dateFnsFr,
	DE: dateFnsDe,
	PT: dateFnsPt
};

const THRESHOLD_HOURS = 24;
const DATE_FORMAT = 'LLLL d, y';
const addPrefix = true;
const addSuffix = true;
const currentDate = Date.now();

/**
 * @function TimeElapsed
 * @description provides time elapsed from date prop to present
 * If the hours elapsed is greater than THRESHOLD_HOURS, this provides a formatted date
 * TimeElapsed is translated into supported languages for international users
 * currentDate is calculated when page is loaded from server
 * @param {String} props.date
 * @returns {React.ReactElement}
 */
const TimeElapsed = ({ date }) => {
	const { lang } = useContext(PageContext);
	const itemDate = parseISO(date);
	const hoursElapsed = differenceInHours(currentDate, itemDate);

	return hoursElapsed > THRESHOLD_HOURS
		? format(itemDate, DATE_FORMAT, { locale: locales[lang] })
		: formatDistanceToNow(parseISO(date), {
				locale: locales[lang],
				addPrefix,
				addSuffix
		  });
};

TimeElapsed.propTypes = {
	date: string.isRequired
};

export default TimeElapsed;
