import React from 'react';
import { array, bool, object, oneOfType, string } from 'prop-types';
import Thumb from '@/components/thumb/Thumb';
import SnipeBanner from '@/components/snipe-banner/SnipeBanner';
import Caption from '@/components/caption/Caption';
import { usePageContext } from '@/components/layout/page/PageContext';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import intlUrl from '@/components/i18n/intlUrl';
import GatedLink from '@/components/identity/GatedLink';
import './Hero.scss';

/**
 * @function Hero
 * @param {Object} props
 * @param {Object} [props.analyticsData]
 * @param {Array} [props.authors]
 * @param {Object} [props.children]
 * @param {String} [props.publishDate]
 * @param {String} [props.firstPublishDate]
 * @param {String} [props.snipe]
 * @param {Object} [props.thumbnail]
 * @param {String} [props.thumbType] - i.e. 16x9, 1x1, 2x1, or 3x4
 * @param {String} [props.title]
 * @param {String} [props.description]
 * @param {String} [props.uri] - path to linked content
 * @param {String} [props.type] (content type - ARTICLE, GALLERY, or VIDEO)
 * @param {String} [props.id] The reference.id of the item being rendered
 * @param {String} [props.width]
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Boolean} [props.descriptionEnabled]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {Boolean} [props.isThumbLazyLoadDisabled]
 * @return {React.ReactElement}
 */

const Hero = ({
	analyticsData,
	authors,
	children,
	id,
	publishDate,
	firstPublishDate,
	snipe,
	thumbnail,
	thumbType,
	title,
	description,
	uri,
	type,
	width,
	timestampsEnabled,
	descriptionEnabled,
	authorBylineEnabled,
	isThumbLazyLoadDisabled
}) => {
	const { edition } = usePageContext();
	const href = intlUrl(edition, uri);

	return thumbnail && width ? (
		<div className="hero" data-tb-region-item>
			<div className="hero__thumb">
				<Thumb
					analyticsData={analyticsData}
					href={href}
					thumbnail={thumbnail}
					title={title}
					type={thumbType}
					width={width}
					width2x="828"
					videoId={type === 'VIDEO' ? id : null}
					lazyLoad={!isThumbLazyLoadDisabled}
				>
					{snipe && (
						<SnipeBanner
							snipe={snipe}
							color="WHITE"
							inset={true}
							firstPublishDate={firstPublishDate}
						/>
					)}
					<ContentItemIcon type={type} />
				</Thumb>
			</div>
			<GatedLink className="hero__caption" {...analyticsData} href={href}>
				<Caption
					authors={authors}
					publishDate={publishDate}
					title={title}
					description={description}
					timestampsEnabled={timestampsEnabled}
					descriptionEnabled={descriptionEnabled}
					authorBylineEnabled={authorBylineEnabled}
				/>
			</GatedLink>
			{children}
		</div>
	) : null;
};

Hero.defaultProps = {
	thumbType: '16x9',
	isThumbLazyLoadDisabled: false
};

Hero.propTypes = {
	analyticsData: object,
	authors: array,
	children: oneOfType([bool, object]),
	id: string,
	publishDate: string,
	firstPublishDate: string,
	snipe: string,
	thumbnail: object,
	thumbType: string,
	title: string,
	description: string,
	uri: string,
	type: string,
	width: string,
	timestampsEnabled: bool,
	descriptionEnabled: bool,
	authorBylineEnabled: bool,
	isThumbLazyLoadDisabled: bool
};

Hero.displayName = 'Hero';

export default Hero;
