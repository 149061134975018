import React from 'react';
import { string, bool } from 'prop-types';
import Translate from '@/components/i18n/Translate';
import './SnipeBanner.scss';

/**
 * @function SnipeBanner
 * @description displays a banner with text
 * @param {Object} props
 * @param {String} props.snipe - text the banner should display
 * @param {Boolean} [props.line] - if the snipe banner should display a line next to the snipe text
 * @param {Boolean} [props.inset] - if the snipe banner is inset in a Thumb component
 * @param {String} [props.color] - The snipe banner color. WHITE || DEFAULT
 * @param {String} [props.className]
 * @param {String} [props.firstPublishDate]
 * @returns {React.ReactElement}
 */
const SnipeBanner = ({ snipe, color, line, inset, className, firstPublishDate }) => {
	let timeDifferenceInHours;
	// Breaking banner should only appear for 12 hours
	if (snipe === 'BREAKING') {
		const givenDate = new Date(firstPublishDate);
		timeDifferenceInHours = (new Date() - givenDate) / (1000 * 60 * 60);
		if (timeDifferenceInHours > 12) {
			snipe = null;
		}
	}
	const colorClassName = color === 'WHITE' ? 'snipe-banner--white' : '';
	const insetClassName = inset ? 'snipe-banner--inset' : '';

	return snipe ? (
		<div className={`snipe-banner ${colorClassName} ${insetClassName} ${className}`}>
			<div data-hook="snipebanner-text" className="snipe-banner__text">
				<Translate word={snipe} />
			</div>
			{line && <div data-hook="snipebanner-line" className="snipe-banner__line"></div>}
		</div>
	) : null;
};

SnipeBanner.defaultProps = {
	line: false,
	inset: false,
	className: ''
};

SnipeBanner.propTypes = {
	snipe: string.isRequired,
	line: bool,
	inset: bool,
	color: string,
	className: string,
	firstPublishDate: string
};

SnipeBanner.displayName = 'SnipeBanner';

export default SnipeBanner;
