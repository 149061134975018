import { useQuery } from '@apollo/client';
import { usePageContext } from '@/components/layout/page/PageContext';
import SITE_THEME from '@/graphql/queries/SITE_THEME';

/**
 * @function useSiteThemeQuery
 * @param {Boolean} skip if set to true the query will be skipped
 * @returns {Object}
 */
export default (skip) => {
	const { uppercaseEdition } = usePageContext();
	const { data, error } = useQuery(SITE_THEME, {
		variables: { edition: uppercaseEdition },
		context: { noBatch: true },
		skip
	});

	if (error) {
		return null;
	} else {
		return data;
	}
};
