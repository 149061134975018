import React from 'react';
import List from '@/components/list/List';
import CONTENT_BY_CATEGORY from '@/graphql/queries/CONTENT_BY_CATEGORY';
import { useQuery } from '@apollo/client';
import { usePageContext } from '@/components/layout/page/PageContext';

/**
 * @function LatestNews
 * @description renders 7 top stories articles
 * @returns {React.ReactElement}
 */

const LatestNews = () => {
	const { edition } = usePageContext();
	const upperEdition = edition.toUpperCase();
	const { data, error } = useQuery(CONTENT_BY_CATEGORY, {
		variables: { categoryKey: 'top_stories', edition: upperEdition, limit: 7 }
	});

	if (!data?.articles) {
		if (error) console.log('error in Latest News query:', error.message);
		return null;
	}

	return (
		<List
			className="latest-news"
			header={{ title: 'LATEST_NEWS' }}
			contentItems={data.articles.nodes}
			showMeta={false}
			analyticsData={({ type }) => ({
				'data-analytics-right-rail-content-type': type,
				'data-analytics-linklocation': 'article-right-rail-latest-news'
			})}
			options={{
				hideAuthors: true,
				hideDate: true,
				hideSeeMore: true,
				showThumbs: true
			}}
		/>
	);
};

LatestNews.displayName = 'LatestNews';

export default LatestNews;
