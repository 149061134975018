import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLandingPageContext } from '@/components/pages/landing/LandingPageContext';
import ContentFeed from '@/components/pages/landing/content/ContentFeed';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import Loading from '@/components/loading/Loading';
import useContentQuery from './useContentQuery';

/**
 * @function Content
 * @description provides a Content Feed with content data from API source
 * @returns {React.ReactElement}
 */
const Content = () => {
	const { showWidgets, showFeedHeader, translations } = useLandingPageContext();
	const { data, loading } = useContentQuery();
	if (loading) return <Loading />;
	const nodes = data?.contentLinkables?.nodes || [];
	const contentItems = nodes;
	const totalCount = parseInt(data?.contentLinkables?.totalCount);

	return contentItems.length >= 1 ? (
		<div className="theme-full-width">
			{!showWidgets && contentItems.length < 5 && (
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
			)}
			{showFeedHeader && <WidgetHeader title={translations.NEWS || 'News'} />}
			<ContentFeed contentItems={contentItems} totalCount={totalCount} />
		</div>
	) : null;
};

Content.displayName = 'Content';

export default Content;
