import React from 'react';
import { bool, any } from 'prop-types';

/**
 * @function ThemeThemeFullWidthWrap
 * @description Adds a style wrap for thematic pages to cover the theme background image & color
 * @param {Boolean} [hasWrap]
 * @param {Any} children
 * @return {React.ReactElement}
 */
const ThemeFullWidthWrap = ({ hasWrap, children }) => {
	return hasWrap ? <div className="theme-full-width">{children}</div> : children;
};

ThemeFullWidthWrap.propTypes = {
	hasWrap: bool,
	children: any.isRequired
};

ThemeFullWidthWrap.displayName = 'ThemeFullWidthWrap';

export default ThemeFullWidthWrap;
