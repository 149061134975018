import React from 'react';
import { bool, number, object, string } from 'prop-types';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import Thumb from '@/components/thumb/Thumb';
import Author from '@/components/widgets/WidgetAuthor';
import WidgetDate from '@/components/widgets/WidgetDate';
import WidgetTitle from '@/components/widgets/WidgetTitle';
import SnipeBanner from '@/components/snipe-banner/SnipeBanner';
import ListCount from './ListCount';
import './ListRow.scss';

/**
 * @function ListRow
 * @param {Object} props
 * @param {Object} [props.analyticsData]
 * @param {Number} [props.count]
 * @param {Object} props.displayImage
 * @param {String} props.displayTitle
 * @param {Boolean} props.isCurated
 * @param {Object} props.options
 * @param {Object} props.reference
 * @param {String} props.linkType
 * @param {Boolean} [props.isThumbLazyLoadDisabled]
 * @return {React.ReactElement}
 */
const ListRow = ({
	analyticsData,
	count,
	displayImage,
	displayTitle,
	isCurated,
	options,
	reference,
	linkType,
	isThumbLazyLoadDisabled
}) => {
	const {
		authors,
		branding,
		publishDate,
		shortTitle,
		snipe,
		uri,
		id,
		firstPublishDate
	} = reference;
	const {
		hideAuthors,
		hideDate,
		hideIcon,
		showCount,
		showThumbs,
		showThumbCount,
		stacked,
		stackedTablet,
		thumbType
	} = options;

	const thumbClass = `list-row__thumb column ${
		stacked
			? 'is-5-desktop is-full-tablet is-full-mobile'
			: stackedTablet
			? 'is-5-desktop is-5-mobile is-full-tablet'
			: 'is-5'
	}`;

	return (
		<div
			className={`row list-row columns ${stacked ? 'is-desktop stacked' : 'is-mobile'}`}
			data-tb-region-item
		>
			{showCount && <ListCount count={count} />}
			{showThumbs && displayImage && (
				<div className={thumbClass}>
					<div className="is-relative">
						<Thumb
							analyticsData={analyticsData}
							type={thumbType}
							href={uri}
							thumbnail={displayImage}
							videoId={linkType === 'VIDEO' ? id : null}
							width="300"
							height="300"
							width2x="300"
							lazyLoad={!isThumbLazyLoadDisabled}
						>
							{!hideIcon ? <ContentItemIcon type={linkType} /> : null}
						</Thumb>
						{showThumbCount && count && (
							<div className="list-row__thumb-number">{count}</div>
						)}
					</div>
				</div>
			)}
			<div className="list-row__content column">
				{snipe && (
					<SnipeBanner snipe={snipe} line={true} firstPublishDate={firstPublishDate} />
				)}
				<WidgetTitle
					className="is-clearfix"
					title={displayTitle || shortTitle}
					href={uri}
					analyticsData={analyticsData}
					isChildrenInsideTitle
				>
					{!showThumbs && /GALLERY|VIDEO/i.test(linkType) ? (
						<div className="list-row__icon-wrap is-pulled-left">
							<ContentItemIcon className="list-row__title-icon" type={linkType} />
						</div>
					) : null}
				</WidgetTitle>
				{!hideAuthors && <Author authors={authors} />}
				{!hideDate && <WidgetDate date={publishDate} />}
				{isCurated && branding && <SponsoredBy {...branding} />}
			</div>
		</div>
	);
};

ListRow.defaultProps = {
	isCurated: false,
	isThumbLazyLoadDisabled: false
};

ListRow.propTypes = {
	analyticsData: object,
	count: number,
	displayImage: object,
	displayTitle: string,
	isCurated: bool.isRequired,
	isThumbLazyLoadDisabled: bool,
	options: object.isRequired,
	reference: object,
	linkType: string
};

ListRow.displayName = 'ListRow';

export default ListRow;
